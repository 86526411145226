import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../../api/Api';
import { fetchCvs } from '../../../Endpoints/Endpoints';
import Ec2Url from '../../../api/Service';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams } from "react-router-dom";
import FileViewer from 'react-file-viewer';
import DocViewer,{ DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AllDocViewer from 'all-doc-viewer-fixed';
import Iframe from "react-iframe";
import backgroundimage from "../../../Assets/images/background-10.png"
import Logout from "../../Logout/logout";
import backgroundTopImg from "../../../Assets/images/Rectangle-1.png"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
});


const ViewCvfromUser = ()=>
{
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
    const {state} = useLocation();
    const {testvalue}=useParams();
    // console.log(localStorage.getItem("cvuser"),"testValueComing");
    //const cvurl=localStorage.getItem("cvuser");
     const cvurl=state.data;
    const url=`//docs.google.com/viewer?embedded=true&url=${cvurl}`;

    async function handleCloseUserMenu() {
      setAnchorElUser(null);
      //localStorage.clear();
    };
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
      
    const userLogout =()=>{
      setAnchorElUser(null);
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }

    const handleToHomePage = (event) =>
    {
        navigate("/View-cvs");
    }

    return <div>
    <Box sx={{ flexGrow: 1 }}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary" enableColorOnDark>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <div className="logo" style={{ display: "flex" }}>
                    <div className="logo-div">
                      <img
                        className="absyzimg image"
                        style={{ height: "50px" }}
                        src={require("../../../Assets/images/absyz_blue.png")}
                        alt="Absyz"
                      />

<div className="logo-text">
                        <p
                          className="pre-salestext"
                          style={{ marginTop: "5px", marginBottom: "-10px", color: "rgba(11, 38, 99, 1)",fontSize:"20px",fontWeight:"600" }}
                        >
                          Pre-sales
                        </p>
                        <span
                          className="assistented-proposalText"
                          style={{ color: "#3e3e3c", fontSize:"14px",lineHeight:"21px",fontWeight:"400"}}
                        >
                          Assisted proposal creation
                        </span>
                      </div>

                    </div>
                    {/* <Box sx={{ flexGrow: 0 , marginTop:"10px"}}>
                        <Tooltip title="Open settings">
                          <IconButton
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0 }}
                          >
                         <Avatar style={{color:"cornflowerblue", backgroundColor:"white"}} alt="Remy Sharp" src="" />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: "45px" }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          <MenuItem >
                            <Typography textAlign="center">{state.uname}</Typography>
                          </MenuItem>
                          <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                            <Typography textAlign="center">
                              <Button style={{backgroundColor:'black'}}onClick={()=>{userLogout()}}>Logout&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box> */}
                      <Logout/>
                  </div>
                </Typography>
               
              </Toolbar>
            </AppBar>
          </ThemeProvider>
          
        </Box>
        <div style={{height:"100%",backgroundImage: `url(${backgroundimage})`,backgroundSize:"cover"}}>
        <div
        style={{
          height: "7%",
          width: "100%",
          // backgroundColor: "rgba(66, 185, 197, 1)",
          display: "flex",
          alignItems: "center",
          backgroundImage: `url(${backgroundTopImg})`
        }}
      >
        <div className="element_style">
          <Button
            className="button"
            style={{ cursor: "pointer",textTransform:"capitalize",fontFamily:"unset"}}
            onClick={handleToHomePage}
          >
            <ArrowBackIosIcon style={{height:'16px',marginTop:"2px"}}/>
            Back
          </Button>
          <div
            className="text"
            // style={{ width:"100%",display:"flex",justifyContent:"center" }}
          >
            View CVs
          </div>
        </div>
      </div>
    <div className="viewer doc" style={{width:"100%",height:"100vh",display:"flex",justifyContent:"center",marginTop:"0 px",marginBottom:"0px"}}>

    <Iframe className="frame" src={url} width="840" height="auto" scrolling="no" frameBorder={"none"} styles={{justifyContent:"center"}}/>
    {/* <iframe src="//docs.google.com/viewer?embedded=true&url=https://absyz-presales.s3.ap-south-1.amazonaws.com/cv/Haseeb_TA_ABSYZ_Others.docx" width="600" height="780" style={}> */}
  </div>
  </div>
  </div>

 
    

}

export default ViewCvfromUser;