import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Avatar from '@mui/material/Avatar';
import "./createppt.css";
import Checkbox from '@mui/material/Checkbox';
import { array } from 'yup';
import { useState } from 'react';
import { useEffect } from 'react';
import backgroundimage from "../../../Assets/images/background-10.png"

const Title_Info =["Pre-Sales",'Assisted Proposal Creation'];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // console.log(slides,"slides")

  return (
    
    <div role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
         
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



export default function VerticalTabs2(props) {
  const [value, setValue] = useState(props.slideVal);
  const {image,setImage}=props;
  
  // console.log(props,"Check for props")
  const getSource = (e,val)=>{
    console.log(val);
  }
  const {Id,file,myRef,slideVal,setSlideVal,presentation,setPresentation}=props;
  const [present,setPresent]=useState(presentation);
  //myRef.current.push([]);

  useEffect(()=>{
    console.log(present,"Selected Present");
    myRef.current.push(present);
    //setStatus(true);
    console.log(myRef,"My ref check")

   
  },[present])
  const handleChange = (event, newValue) => {
   // setSlideVal(newValue);
     setValue(newValue);
   // setImage(newValue);
    //setValue(event.target);
  };
  //console.log(props.mp,"MAPPED");
  const [image_url,setImage_url]=React.useState();
  const [indexed,setIndex]=React.useState();
  const [slideId,setSlideId]=React.useState();
 
  const CheckboxHandle = (present,items)=>{
    var checkdata = false
    present?.map((each) => {
       // console.log(each.imageUrl,items.imageUrl,(each.imageUrl === items.imageUrl), 'item id')
        return each.imageUrl !== items.imageUrl})
  
        present?.forEach(each =>{
          if(each.imageUrl === items.imageUrl){
            checkdata = true
          }
      })
      return checkdata
      // forEach
  }
      const TabSlide=(image_url,slideid,i)=>
      {
         
       // setImage(i);
          // setStatus("ADD");
          // setImage_url(image_url);
          // setIndex(i);
          // setSlideId(id);
          
          let array = [
            {
                unique:slideid,
                id: Id,
                name:file,
                imageUrl: image_url,
                slides:[i+1]
            },
          ];
      
       
       setPresent(array);  
   }
    
  return <Box style={{backgroundImage: `url(${backgroundimage}`,backgroundSize:"cover"}}
  sx={{ 
    // flexGrow: 1, 
    bgcolor: 'background.paper', 
    display: 'flex', 
//  height: 224 
}}
>
  
<div className='main-div-1' style={{height:"100vh"}}>
  <Tabs
       orientation="vertical"
       variant="scrollable"
       scrollButtons="false"
       value={value}
       onChange={handleChange}
       aria-label="Vertical tabs example"
       sx={{ borderRight: 1, borderColor: "divider" }}
       className="tab_widthstyle"
      //  style ={{height:"100vh",display:"flex", overflowY:"",backgroundColor:"rgba(66, 185, 197, 1)"}}
    >
     {props.slidedata.map((items,index)=>(
      
     <Tab className="slide-list"
     style={{cursor:"unset"}}
      icon={
      <>
       <div className="slide-div" style={{backgroundImage:`url(${items.imageUrl})`}}>
        

       <Checkbox checked={CheckboxHandle(present,items)} style={{ marginLeft: "160px", marginTop:"4px", backgroundColor:"white", height:"17px" , width:"17px"}}
        {...label} color="primary" onChange={()=>{TabSlide(items.imageUrl,items.slideId,index);
        
       }
      }/>

       </div>
       
       
      </>  
      }
    
    

key={index} {...a11yProps(index)} />
))}
</Tabs>
</div>

 
<div className='titleshowbackground'>
  <div className="background-1" style={{
    // height:"81%",
    // width:"100%",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"center"
    }}>
      <div style={{height:"100%",width:"78%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <img
   alt="image"
     src={props.slidedata[value].imageUrl}
     className="tab-image"
     style={{
     display:"flex",
     alignItems:'center',
     justifyContent:"center",
     width:"82%",
    }}
     />
     </div>
     </div>
     </div>  
  


     </Box>
}
  