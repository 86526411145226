import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect } from "react";
import './managepresentation.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from 'react-bootstrap';
import { ContactSupportOutlined, Queue } from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Footer from "../../footer/Footer"
import PQueue from 'p-queue';
import LogoutIcon from '@mui/icons-material/Logout';
import { decryptData } from '../../SecureLocal/Utill';
import BASE_URL from '../../../api/Api';
import Ec2Url from '../../../api/Service';
import { getCategories, getPpts } from '../../../Endpoints/Endpoints';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
});

function TabPanel(props) {


    const { children, value, index, ...other } = props;
   
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function ManagePresentation(props) {

  const [auth, setAuth] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState();
  const [dataHandler, setDataHandler] = useState();
  const [rowdata,setRowdata]=useState([]);
  const [dataslide,setDataslide]= useState()
  console.log(dataHandler, "Data Handler")
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [userName,setUserName]=useState("");
  const navigate = useNavigate();
  
   
  
 

  const queue =new PQueue({concurrency:1});
  useEffect(()=>{
    
       apicall();
       userData();
       
  },[])

    async function apicall(){
        
        const token = JSON.parse(localStorage.getItem("Token"));
        console.log(token["token"], "token");
        const AuthStr = "Bearer " + token["token"]; 
        
        await axios 
        .get( `${Ec2Url}${getPpts}`,{
        headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
        },
        //   body: JSON.stringify(item),
        })
        .then((response) => {
            console.log("response-admin", response.data);
            console.log(response.data["result"],"response-admin-result");
             setDataHandler(response.data["result"].filter((d)=>d.id!=3));
             console.log(dataHandler,"response-admin-result");
             return response.data["result"].filter((d)=>d.id!=3);
          })
          .catch((error) => {
            console.log(error, "Error check")
          })
          .then((data)=>{
          let serializedArray = [];
          console.log(data,"data-coming");
          var i = 1;
          data?.forEach(element => {
               axios(`${Ec2Url}${getCategories}${element.id}`,{
                 method:"GET",
               }).then(function(response){
                 i++
                   console.log(response.data["result"][0],"response404");

                   let array1 = 
                     {
                       id:response.data["result"][0].id,
                       filename:response.data["result"][0].visibleName,
                       count:response.data["result"][0].slides.length,
                       createdDate:response.data["result"][0].createdDate,
                       slides:response.data["result"][0].slides
                     }
                     console.log(array1,"array404");
                   serializedArray.push( array1);
                  //  setRowdata([...rowdata,array1])
                   
                  //  setRowdata([...rowdata,array1)
                  console.log(data.length,"Lenght of data")
                  if(data.length === i+1){
                    console.log(serializedArray,"Chech with serialized array")
                  setRowdata(serializedArray)
                  }
                   
               }).catch(function(e){
                 console.log(e);
               })
         });
         console.log(serializedArray,"array1");
        })
  
    }
    const userData = ()=>{
      let mkLocalData = sessionStorage.getItem('data');
      if(!mkLocalData)
      {
           
      }
      const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
      let originalData = decryptData(mkLocalData, salt);
      console.log(originalData,"originalData1");
      setUserName(originalData.uname);
      
    }
    const convertDT = (date)=>{
      let stringDate = date.toString()
      const dataArray = stringDate.split("T")
      const dateA = dataArray[0];
      const timeA = dataArray[1];
      const dateArray = dateA.split("-")
      const dateDay = dateArray[2]
      const dateMonth = dateArray[1]
      const dateYear = dateArray[0]
      const timeArray = timeA.split(":")
      const timeHour = timeArray[0]
      const timeMinute = timeArray[1]
  
      const simpleDateTime = dateDay+"/"+dateMonth+"/"+dateYear+" "+timeHour+":"+timeMinute
  
      return simpleDateTime;
    };

    const handleOnClickTable =  (data) => { 
       // const data =  handleOnClick(id);
       if(data && userName){
        navigate("/slidepresenter", { state: {data,userName} })
       }
        console.log(data, "Check with data for navigate") 
    }
   

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      const handleChangeIndex = (index) => {
        setValue(index);
      };
    
    //const requiredslidedata = location.state.dataslide
    
    const handletoAdminPage = () => {
      navigate('/Admin')
    }
    const toCvPage = ()=>
    {
      if(userName)
        navigate('/To-Cvs',{state:{userName}})
    }
    const handletoAddPage = () => {
      navigate('/Add-Slide')
    }
    async function handleCloseUserMenu() {
      setAnchorElUser(null);
    };
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  const userLogout =()=>
  {
    setAnchorElUser(null);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }
return (
<>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px",fontSize: "16px",fontWeight: "600"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{color:"black", backgroundColor:"transparent"}} alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem style={{display: "flex", justifyContent:"center"}}>
               <Typography textAlign="center">{userName}</Typography>
               </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                  <Typography textAlign="center">
                  <Button style={{backgroundColor:'black',color:"white",borderRadius:"4px"}}onClick={()=>{userLogout()}}>LOGOUT&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                    </Typography>
                </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header'>
    <div style={{width:"50%"}}><Button className='Button-butter'onClick={handletoAdminPage}>back</Button></div>
    
    <div style={{width:"50%", display:"flex",justifyContent:"flex-end"}}><Button className='button-text2' onClick={toCvPage}>Manage Cvs</Button></div>
    <div><Button className='button-text' onClick={handletoAddPage}>Add New</Button></div>
    </div>
    </AppBar>
    </ThemeProvider>
    </Box>
    <Box className="manage-presentation-box">  
      <Box style={{height:"60%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <div style={{width:"100%",padding:"5px"}}className='header-1'>Master Presentations</div>
    <div className='content-1'>
    
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">File Name</TableCell>
            <TableCell align="center">Slide Count</TableCell>
            <TableCell align="center">Created Date</TableCell>
            <TableCell align="center">Action</TableCell>
        
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rowdata?.map((row,each) => (
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="center">{row.filename}</TableCell>
              <TableCell align="center">{row.count}</TableCell>
              <TableCell align="center">{convertDT(row.createdDate)}</TableCell>
              <TableCell align="center" style={{padding:"10px"}}><Button onClick={()=>{handleOnClickTable(row.slides)}} className='Button-butter-1'>View</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </Box>
    </Box>
    {console.log(dataslide,"qwedfty")}
    <div>
    {/* <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {dataslide?.map((items,index)=>(
          <Tab className="slide-list"
          icon={<img src={items.imageUrl} className="tab-image" 
          style={{height: "105px",border: "2px solid #0F2C67"}}
        />} key={index} {...a11yProps(index)} />
         ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       {dataslide?.map((item, index) => (
          <TabPanel value={value} index={index}>
            <img
              src={item.imageUrl}
              className="tab-image"
              style={{
                height: "590px",
                marginLeft: "140px",
                marginTop: "10px",
              }}
              alt=""
            />
            
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box> */}
   
    </div>
    <Footer/>
</>
);
}