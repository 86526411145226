import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { decryptData } from "../SecureLocal/Utill";
import { Admin } from "./admin";
import { Home } from "./Home";
import Login from "./Login";


const getRole = () => {
 
  let mkLocalData = sessionStorage.getItem('role');
  
  if (!mkLocalData) {
     return JSON.stringify("none");
  }
  else{
    const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
    let originalData = decryptData(mkLocalData, salt);
    //console.log(originalData, "originalData1");
    return originalData;
  }
 

}
export const ProtectedRoute = () => {
  const navigate=useNavigate();
  const Token = localStorage.getItem("Token").length;
  const decryptrole = getRole();
  if(decryptrole==="none")
  {
    navigate("/");
  }
  const Role = JSON.parse(decryptrole).role;
   console.log(Role,"role check");
  if (Token > 2 && Role >= 2 ) {
    return <Outlet />
  } else {
    return <Navigate to="/" />
  }
};

export const ProtectedAdminRoute = () => {
  const Token = localStorage.getItem("Token").length;
  const decryptrole = getRole();
  const Role = JSON.parse(decryptrole).role;
  if (Token > 2 && Role === 1) {
    return <Outlet />
  } else {
    return <Navigate to="/" />
  }
}
