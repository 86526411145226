import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./reorder.css";
// import { Notes } from "./Notes";
import { useState } from "react";
import { Reorder } from "framer-motion";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from "react";
import {
  AppBar,
  FormHelperText,
  Icon,
  Input,
  LinearProgress,
  OutlinedInput,
  Stack,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { SpinnerRoundFilled } from "spinners-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { color } from "@mui/system";
import { Form } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ec2Url from "../../../api/Service";
import { createMerge } from "../../../Endpoints/Endpoints";
import BeanEater from "../../../Assets/images/BeanEater.gif";
import frame from "../../../Assets/images/Frame1.png"
import frame2 from "../../../Assets/images/Frame.png"
import frame3 from "../../../Assets/images/Frame-1.png"
import {MyForm} from "../../MyForm/MyForm";
import ReusableInputfield from "../../Login/TextField/TextField";
import backgroundimage from "../../../Assets/images/background-10.png"
import SECRET_KEY from "../../SecureLocal/Secretkey";
import CryptoJS from "crypto-js";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // console.log(slides,"slides")

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Reorder1(props) {
  const [value, setValue] = React.useState(0);
  const [items, setItems] = useState(props.finalArray);
  const [open, setOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileName, setFilename] = useState("");
  const [finalFile, setFinalFile] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [count, setCount] = useState();
  const Navigate = useNavigate();
  // console.log(items,"reorder")
  //   const [fullWidth, setFullWidth] = useState(true);
  //   const [maxWidth, setMaxWidth] = useState<import("@mui/material").DialogProps['maxWidth']>('sm');
  const { finalArray, setFinalArray, setStatus, userId } = props;
  // console.log(items, "props in verticaltabs");
  //console.log(userId,"coming2");
  useEffect(() => {
    setItems(finalArray);
  }, [finalArray]);

  const handleChange = (event, newValue) => {
    console.log(newValue, "value");
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setFilename("");
  };
  // const handleTextField = (e) => {
  //   setFilename(e.target.value);
  //   //  console.log(filename,"Changing");
  // };
  const timeout =() => {
    const result =  setTimeout(() => {setCount("This can take a upto 2-3 minutes")
   }); 
   console.log(count,"count1")
    const result2 =  setTimeout(() => {setCount("Do not close the window")}, 23000); 
    let result3 =   setTimeout(() => {setCount("Your presentation is getting ready")}, 30000);
    let result4 =  setTimeout(() => {setCount("It's taking a little longer than expected")}, 60000);
    let result5 =  setTimeout(() => {setCount("Please be Patient")}, 70000);
    let result6 =  setTimeout(() => {setCount("We are adding finishing touches")}, 80000);

 }

 const retrievedRole = localStorage.getItem("Mainrole");
  const bytesRole = CryptoJS.AES.decrypt(retrievedRole, SECRET_KEY);
  const buttonrole = JSON.parse(bytesRole.toString(CryptoJS.enc.Utf8));

 const initialValues = {
  filename:"",
 };

 const validate = (inputValues = values) => {
  let code = { ...errors};
 
  if ("filename" in inputValues)
    code.filename = inputValues.filename
      ? ""
      : "Please Enter File Name";
  setErrors({
    ...code,
  });
  console.log(errors,"errordata");
  if (inputValues === values)
    return Object.values(code).every((x) => x === "");
}

const { values, setValues, handleInputChange, errors, validateOnEdit, setErrors, resetForm } =
  MyForm(
    initialValues,
     true, validate);

  const handleFilename = (e) =>{
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value, 
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    setErrors({ ...errors, filename: "" });
    // console.log(e.target.value,"data")
    setFilename(e.target.value)
  }

  console.log(errors,"validationerrors")

  const createHandler = () => {
   if(validate()){
    if (!userId) {
      toast("Session timeout,login again");
      localStorage.clear();
      Navigate("/");
    } else {
      let planename = fileName;
      let RNumber = Math.floor(1000 + Math.random() * 9000).toString();
      planename = planename.replace(/\s+/g, "");
      let finalname = `${planename}.pptx${RNumber}.pptx`;
      setFinalFile(finalname);
      // console.log(finalname,"plane1");
      // console.log(userId,"plane2");
      // console.log(items,"plane3");
      // console.log(planename,"plane4");
      let serializedArray = [];
      items.forEach((element) => {
        let array1 = {
          id: element.id,
          name: element.name,
          imageUrl: element.imageUrl,
          slides: element.slides,
        };
        serializedArray.push(array1);
      });
      // console.log(serializedArray,"ToApi")
      const array = {
        fileName: finalname,
        userId: userId.uid,
        presentations: serializedArray,
        visibleName: planename,
      };
      // console.log(array,"plane5");
      const token = JSON.parse(localStorage.getItem("Token"));
      const AuthStr = "Bearer " + token["token"];
      setAnimation(true);
      
      //setSuccess(true);
      setOpen(false);
      
      axios
        .post(`${ec2Url}${createMerge}`, array, {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
        })
        .then(
          (Response) => {
            setAnimation(false);
            setSuccess(true);
            console.log(Response.data.result, "fileLink");
            setFileLink(Response.data.result);

            console.log(Response.message, "ResponseMessage");

            console.log(Response);
            console.log("success");
          },
          (error) => {
            if(error.response.data.message==="Token Expired")
            {
             localStorage.clear();
             sessionStorage.clear();
             Navigate("/");
            }
            setAnimation(false);
            setError(true);
            console.log(error);
            
          }
        );
    }
    timeout();
  }
  };

  const navigation = (e) => {
    if (e.target.value === "View Files") {
      if (userId) {
        let udata = userId;
        Navigate("/ViewFiles", { state: { udata } });
      }
    }
  };

  let removeHandler = (id) => {
    const newList = items.filter((l) => l.unique !== id);
    // console.log(newList,"Deleting");
    setItems(newList);
  };

  const changeIndex = (newValue) => {
    setValue(newValue);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    // link.download = finalFile;
    link.href = fileLink;
    link.click();
  };
  const [deleteIcon, setdeleteIcon] = useState(null);

  const [hover, setHover] = useState(false);
  const onHover = (event) => {
    // console.log(event,"index01111");
    // console.log("Inside mouse hover");
    setHover(true);
    setdeleteIcon(event);
  };

  const onLeave = () => {
    // console.log("Inside mouse hover leave");
    setHover(false);
  };

  const home = (e) =>{
    // if (e.target.value === "Home") {
      // console.log(e.target.value,"home target")
      if (userId) {
        let udata = userId;
        Navigate("/User", { state: { udata } });
      }
    // }
  }

  return (
    <>
      <Dialog open={animation} fullWidth maxWidth="xs">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{ textAlign: "center", color: "grey.500" }}
              className="stack-spinner"
              spacing={2}
            >
              <img src={BeanEater} style={{height:"180px"}}/>
              {/* <LinearProgress color="success" /> */}
            </div>

            <div
              style={{
                textAlign: "center",
                color: "#171313",
                fontSize: "16px",
              }}
            >
              {/* {timeout} */}
              <h4> {count}</h4>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setAnimation(false)}}>X</Button> */}
        </DialogActions>
      </Dialog>
      <Dialog open={error} fullWidth maxWidth="xs">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                marginLeft: "130px",
                color: "#171313",
                fontSize: "16px",
              }}
            >
              Error..
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setError(false);
            }}
          >
            X
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={success} fullWidth maxWidth="xs">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "215px",
                color: "#171313",
                fontSize: "20px",
              }}
            >
              <div style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height:"50%"
                }}>
              <img
                src={frame3}
                alt=""
              >
              </img>
              </div>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize:"22px",
                  fontWeight:"600",
                  color:"rgba(12, 38, 99, 1)",
                }}
              >
                Voila !
              </span>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize:"20px",
                  fontWeight:"600"
                }}
              >
                Presentation Created Successfully
              </span>
            </div>
          </DialogContentText>
          <Box style={{ width: "100%", display: "flex" ,paddingBottom:"15px"}}>
            <Box
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: "rgba(12, 38, 99, 1)",
                  textTransform: "capitalize",
                  width:"80%",
                  fontSize:"18px",
                  paddingTop:"3px",
                  paddingBottom:"3px"
                }}
                onClick={onDownload}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Box>
            <Box
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: "rgba(98, 175, 123, 1)",
                  textTransform: "capitalize",
                  width:"80%",
                  fontSize:"18px",
                  paddingTop:"3px",
                  paddingBottom:"3px"
                }}
                value={"View Files"}
                onClick={navigation}
                variant="contained"
              >
                View Files
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      { buttonrole === 3 ? (<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText style={{width:"auto",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            {/* <DriveFileRenameOutlineIcon
              style={{
                fontSize: "7rem",
                color: "black",
                alignContent: "center",
              }}
            /> */}
            {/* <img 
            src={frame}
            alt="">
            </img>
            <div
              style={{
                color: "rgba(11, 38, 99, 1)",
                fontSize: "20px",
                fontWeight:"600"
              }}
            >
              Enter File Name
            </div> */}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            className="boxcomponent"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {/* <TextField
              className="incommingName"
              variant="outlined"
              value={filename}
              label="Enter File Name"
              InputLabelProps={{
                sx: { marginBottom: "10px", width: "45ch !important" },
              }}
              required
              onChange={handleTextField}
              style={{ marginTop: "20px", width: "100%" }}
              autoComplete="off"
            /> */}

                    {/* <ReusableInputfield
                      id="outlined-password-input"
                      label="Enter File Name"
                      type="text"
                      name="filename"
                      autoComplete="off"
                      value={values.filename}
                      error={errors.filename ? true: false}
                      helperText={errors.filename}
                      onChange={handleFilename}
                    /> */}

            {/* <div className="right-div" style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
              <Button
                variant="outlined"
                className=""
                style={{
                  color: "rgba(11, 38, 99, 1)",
                  width: "164px",
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className=""
                style={{
                  backgroundColor: "rgba(56, 107, 73, 1)",
                  color: "white",
                  width: "164px",
                  marginLeft: "34px",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  createHandler();
                }}
              >
                <span>Create</span>
              </Button>
            </div> */}
            <div>
              <p style={{textAlign:"center",fontWeight:700,color:"rgba(12, 38, 99, 1)"}}>You are restricted from creating and downloading the presentation as presentation slides are intellectual property of Absyz Inc.</p>
              <p style={{textAlign:"center",fontWeight:700,color:"rgba(98, 175, 123, 1)"}}>We appreciate you effort of taking a moment to know the application</p>
              <div style={{width:"100%",display:"flex",justifyContent:"center"}}><Button style={{backgroundColor:"rgba(12, 38, 99, 1)",color:'white'}} onClick={home}>Home</Button></div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>) : (<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText style={{width:"auto",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            {/* <DriveFileRenameOutlineIcon
              style={{
                fontSize: "7rem",
                color: "black",
                alignContent: "center",
              }}
            /> */}
            <img 
            src={frame}
            alt="">
            </img>
            <div
              style={{
                color: "rgba(11, 38, 99, 1)",
                fontSize: "20px",
                fontWeight:"600"
              }}
            >
              Enter File Name
            </div>
          </DialogContentText>
          <Box
            noValidate
            component="form"
            className="boxcomponent"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {/* <TextField
              className="incommingName"
              variant="outlined"
              value={filename}
              label="Enter File Name"
              InputLabelProps={{
                sx: { marginBottom: "10px", width: "45ch !important" },
              }}
              required
              onChange={handleTextField}
              style={{ marginTop: "20px", width: "100%" }}
              autoComplete="off"
            /> */}

                    <ReusableInputfield
                      id="outlined-password-input"
                      label="Enter File Name"
                      type="text"
                      name="filename"
                      autoComplete="off"
                      value={values.filename}
                      error={errors.filename ? true: false}
                      helperText={errors.filename}
                      onChange={handleFilename}
                    />

            <div className="right-div" style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
              <Button
                variant="outlined"
                className=""
                style={{
                  color: "rgba(11, 38, 99, 1)",
                  width: "164px",
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className=""
                style={{
                  backgroundColor: "rgba(56, 107, 73, 1)",
                  color: "white",
                  width: "164px",
                  marginLeft: "34px",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  createHandler();
                }}
              >
                <span>Create</span>
              </Button>
            </div>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>)}
     

      <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={darkTheme}>

          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <div className="logo" style={{ display: "flex" ,padding:"5px"}}>
                  <div className="logo-div">
                    <img
                      className="absyzimg image"
                      style={{ height: "50px", marginBottom:'5px' }}
                      src={require("../../../Assets/images/absyz_blue.png")}
                      alt="Absyz"
                    />
                    <div className="logo-text">
                      <p
                        className="pre-salestext"
                        style={{ marginTop: "5px", marginBottom: "-10px", color: "rgba(11, 38, 99, 1)",fontSize:"20px",fontWeight:"600" }}
                      >
                        Pre-sales
                      </p>
                      <span
                        className="assistented-proposalText"
                        style={{ color: "#3e3e3c", fontSize:"14px",lineHeight:"21px",fontWeight:"400"}}
                      >
                        Assisted Proposal Creation
                      </span>
                    </div>
                  </div>
                </div>
                
              </Typography>
            
              <Stack>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(56, 107, 73, 1)",
                    color: "white",
                    textTransform: "capitalize",
                    height:"35px"
                  }}
                  onClick={handleClickOpen}
                >
                  Continue
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
      </Box>
      <Box style={{width:"100%",display:"flex",backgroundImage: `url(${backgroundimage}`}}>
      <Stack style={{display:"flex",justifyContent:"center",alignItems:"start", marginLeft: "35px" }}>
                <Button
                  variant="dark"
                  style={{
                    fontSize:"18px",
                    color: "white",
                    textTransform: "capitalize",
                    fontFamily:"unset"
                  }}
                  onClick={() => {
                    setStatus(false);
                  }}
                >
                <ArrowBackIosIcon style={{height:"16px"}}/>
                  Back
                </Button>
              </Stack>
              <div style={{width:"80%",display:"flex",justifyContent:"center",color:"white",fontSize:"16px",fontWeight:"600",alignItems:"center"}}>
                    <p className="reorder-text">Selected Slides</p>
                  </div>
      </Box>
      <div>
        <Box style={{backgroundImage: `url(${backgroundimage}`,backgroundSize:"cover"}}
          sx={{
            // flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            //  height: 224
          }}
        >
          <div className='main-div-1' style={{height:"100vh"}}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="false"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
            className="tabs-1"
            // style={{ height: "81vh",backgroundColor:"rgba(66, 185, 197, 1)" }}
          >
            {/* {slidedata.map((items,index)=>(
          <Tab className="slide-list"
          icon={<img src={items.imageUrl} className="tab-image" 
          style={{height: "105px",border: "2px solid #0F2C67"}}
        />} key={index} {...a11yProps(index)} />
         ))} */}

            {/* <Reorder.Group values={items} onReorder={setItems}>
          {items.map((item,index)=>(
            <Reorder.Item key={item.unique} value={item}>
              {console.log(item,"item")}
                    <Tab className="slide-list"
                    icon={<img src={item.imageUrl} className="tab-image" 
                    style={{height: "105px",border: "2px solid #0F2C67", pointerEvents: 'none' }} alt=""
                  />}
                   key={index} {...a11yProps(index)} />
                   </Reorder.Item>    
                  ))}
           </Reorder.Group>       
        </Tabs>  */}

            <Reorder.Group values={items} onReorder={setItems} style={{padding:"0px"}}>
              {items.map((items, index) => (
                <Reorder.Item key={items.unique} value={items}>
                  <Tab
                    style={{ opacity: "1" }}
                    className="slide-list"
                    icon={
                      <div
                        id={index}
                        onMouseEnter={() => onHover(index)}
                        onMouseLeave={onLeave}
                        role="button"
                        tabIndex="-3"
                      >
                        <div
                          value={index}
                          className="slide-div"
                          style={{ backgroundImage: `url(${items.imageUrl})`}}
                          key={items.unique}
                          onClick={() => changeIndex(index)}
                        >
                          {!hover || deleteIcon !== index ? (
                            <></>
                          ) : (
                            <DeleteIcon
                              style={{
                                marginLeft: "160px",
                                marginTop: "3px",
                                color: "red",
                                height: "30px",
                              }}
                              onClick={() => removeHandler(items.unique)}
                            />
                          )}
                        </div>
                      </div>
                    }
                    key={index}
                    {...a11yProps(index)}
                  />
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </Tabs>
          </div>

          <div className='Reorderbackground'>
          {items.map((items, index) => (
          <TabPanel value={value} index={index}>
            <div className="background-2">
            <div 
            style={{height:"100%",width:"95%",display:"flex",justifyContent:"center",alignItems:"center"}}
            >
            <img
              src={items.imageUrl}
              className="tab-image"
              style={{
                display:"flex",
                alignItems:'center',
                justifyContent:"center",
                width:"82%",
                // paddingLeft:"10%",
                paddingTop:"5%"
                // height:"100%"
               }} 
              alt=""
            />
            </div>
            </div>
            {/* <Notes notesData={item.notes} /> */}
          </TabPanel>
        ))}  
          {/* {slidedata.length>0 ? (
  <DropDown slidedata={slidedata}/>
   ):
   <></>
   } */}
   </div>
        </Box>
      </div>
    </>
  );
}

// {slidedata.length>0 ? (
//   <DropDown slidedata={slidedata}/>
//    ):
//    <></>
//    }

//    <Notes notesData={items.notes}/>
