
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from 'react-bootstrap';
import { ContactSupportOutlined } from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './usermanagement.css'
import Footer from "../../footer/Footer"
import LogoutIcon from '@mui/icons-material/Logout';
import { getUsers } from '../../../Endpoints/Endpoints';
import BASE_URL from '../../../api/Api';
import Ec2Url from '../../../api/Service';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
});

function TabPanel(props) {


    const { children, value, index, ...other } = props;
  
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function UserManagement(props) {

  const [auth, setAuth] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState();
  const [dataHandler, setDataHandler] = useState();
  const [dataslide,setDataslide]= useState()
  console.log(dataHandler, "Data Handler")
  
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const {state}= useLocation();
  const navigate = useNavigate();
   
  
  async function handleCloseUserMenu() {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
const userLogout =()=>
{
  setAnchorElUser(null);
  sessionStorage.clear();
  localStorage.clear();
  navigate("/");
}
  useEffect(()=>{
    console.log(apicall().then((data)=>{
        console.log(data, "Promise data")
    }), "api data")
  },[])

    async function apicall(){
        
        const token = JSON.parse(localStorage.getItem("Token"));
        console.log(token["token"], "token");
        const AuthStr = "Bearer " + token["token"]; 
        
        const response = axios 
        .get( `${Ec2Url}${getUsers}`,{
        // method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
        },
        //   body: JSON.stringify(item),
        })
        .then((response) => {
            console.log("response1", response.data);
            console.log(response.data["result"]);
             setDataHandler(response.data["result"]);
          })
          .catch((error) => {
            console.log(error, "Error check")
          });
    }

    const handleOnClickTable =  (id) => { 
        const data =  handleOnClick(id);
        console.log(data, "Check with data for navigate")
        
        
    }
    useEffect(()=>{
        if(dataslide !== undefined){
            console.log(dataslide, "check with data slide")
            navigate("/slidepresenter", { state: {dataslide} })
            }

    },[dataslide])

    const handleOnClick =  (id) => {
       
       console.log(id,"dataHandler")
        axios(
            `${BASE_URL}${getUsers}=${id}`,
            {
            method: "GET",
            }
        )
            .then(function (response) {
                const slidearray = []
            console.log(response.data, "Data Responsed");
            //    console.log(response.data['result'][0]['slides'])
            setDataslide(response.data["result"][0]["slides"]);
            slidearray.push(response.data["result"][0]["slides"])
            console.log("Slide data", slidearray)
            return slidearray
            })
            .catch(function (e) {
            console.log(e);
            return e;
        });
       
        
    };    

    console.log(dataslide,"herooooo")

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      const handleChangeIndex = (index) => {
        setValue(index);
      };
    
    //const requiredslidedata = location.state.dataslide
    
    const handletoAdminPage = () => {
      if(state.userName)
      {
        let userName=state.userName;
        navigate('/Admin',{state:{userName}});
      }
     
    }

    const handletoAddPage = () => {
      
      if(state.userName)
      {
        let userName=state.userName;
        navigate('/Add-user',{state:{userName}});
      }
    }
  
return (
<>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px",fontSize: "16px",fontWeight: "600"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{color:"black", backgroundColor:"transparent"}} alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem style={{display: "flex", justifyContent:"center"}}>
               <Typography textAlign="center">{state.userName}</Typography>
               </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                  <Typography textAlign="center">
                  <Button style={{backgroundColor:'black',color:"white",borderRadius:"4px"}}onClick={()=>{userLogout()}}>LOGOUT&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                    </Typography>
                </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='headers'>
    <div style={{width:"50%"}}><Button className='Button-butter'onClick={handletoAdminPage}>back</Button></div>
    <div  style={{width:"50%",display:"flex",justifyContent:"flex-end"}} ><Button className='button-text' onClick={handletoAddPage}>Add New</Button></div>
    </div>
    </AppBar>
    </ThemeProvider>
    </Box>
    <div style={{width:"100%"}}className='header-1'>User List</div>
    <div className='content-1'>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {console.log(dataHandler,"datahandle")}
          {dataHandler?.map((row,each) => (
            
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center" style={{padding:"10px"}}><Button onClick={()=>{handleOnClickTable(row.id)}} className='Button-butter-1'>View</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    {console.log(dataslide,"qwedfty")}
    <div>
    {/* <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {dataslide?.map((items,index)=>(
          <Tab className="slide-list"
          icon={<img src={items.imageUrl} className="tab-image" 
          style={{height: "105px",border: "2px solid #0F2C67"}}
        />} key={index} {...a11yProps(index)} />
         ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       {dataslide?.map((item, index) => (
          <TabPanel value={value} index={index}>
            <img
              src={item.imageUrl}
              className="tab-image"
              style={{
                height: "590px",
                marginLeft: "140px",
                marginTop: "10px",
              }}
              alt=""
            />
            
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box> */}
    </div>
    <Footer/>
</>
);
}