import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./style.css";
import Button from "@mui/material/Button";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiIntegration } from "../../api/LoginApiCall";
import { showtostmessage } from "../Login/toast";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { encryptData } from "../SecureLocal/Utill";
import { authenticate } from "../../Endpoints/Endpoints";
import Ec2Url from "../../api/Service";
import { MyForm } from "../MyForm/MyForm";
import CryptoJS from "crypto-js"
import SECRET_KEY from "../SecureLocal/Secretkey";

const initialValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  const { setUserId } = props;
  const Navigate = useNavigate();
  const [formStatus, setFormStatus] = useState(true);
  useEffect(() => {
    // localStorage.setItem("Token", JSON.stringify({}));
    checkKey();
  }, []);
  const checkKey = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      const payload = { email: userData.uemail };
      const token = JSON.parse(localStorage.getItem("Token"));
      const AuthStr = "Bearer " + token["token"];
      setFormStatus(false);
      if (payload) {
        axios
          .post(`${Ec2Url}${authenticate}`, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
          })
          .then(
            (Response) => {
              if (Response.status === 200) {
                let data = {
                  uid: userData.id,
                  uname: userData.uname,
                };
                const salt =
                  process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
                const encryptedData = encryptData(data, salt);
                sessionStorage.setItem("data", encryptedData);
                let role2 = JSON.stringify({ role: userData.role });
                const encryptedData2 = encryptData(role2, salt);
                sessionStorage.setItem("role", encryptedData2);
                if (userData.role === 2 || userData.role === 3) {
                  Navigate("/User");
                } else {
                  Navigate("/Admin");
                }
              }
            },
            (error) => {
              if (error.response.data.message === "Token Expired") {
                setFormStatus(true);
              }
            }
          );
      }
    }
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };

    if ("email" in inputValues)
      code.email = inputValues.email ? "" : "Email Address is required";
    if ("password" in inputValues)
      code.password = inputValues.password ? "" : "password is required";
    setErrors({
      ...code,
    });
    //console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  console.log(initialValues.email, "emaildata");

  async function handlelogin(values, props, errors) {
    if (validate()) {
      const Apidata = await apiIntegration(values).then(async (value) => {
        console.log(value, "Final Result");
        if (value.message) {
          showtostmessage(value.message);
        }

        const roles = value.user.role;
        const user_id = value.user.id;
        const username = value.user.name;
        const email = value.user.email;

        let data = {
          uid: user_id,
          uname: username,
        };
        let userData = {
          id: user_id,
          uname: username,
          role: roles,
          uemail: email,
        };

        const encryptRole = CryptoJS.AES.encrypt(JSON.stringify(userData.role), SECRET_KEY).toString();
        localStorage.setItem("Mainrole",encryptRole);
        
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("username", JSON.stringify(userData.uname));
        // localStorage.setItem("Mainrole", JSON.stringify(userData.role));

        setUserId(value.user.id);
        const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
        const encryptedData = encryptData(data, salt);
        sessionStorage.setItem("data", encryptedData);

        if (roles === 2) {
          Navigate("/User");
        } else {
          Navigate("/Admin");
        }
      });
    }
  }
  const userData = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      {formStatus === false ? (
        <>
          <div className="main-container2" style={{ height: "100vh" }}>
            <div className="content">
              <div class="absyz-1">
                <img
                  className="absyzimg2"
                  src={require("../../Assets/images/absyz_blue.png")}
                  alt="Absyz"
                />
              </div>
              <div className="logo-wrapper2">
                <span className="bg-blue">PRE </span>
                <span>- SALES</span>
              </div>

              <div className="splash-progress">
                <LinearProgress style={{ width: "60%", marginLeft: "19%" }} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ToastContainer />
          <div className="main-container">
            <div className="absyz"></div>
            <div>
              <div>
                <img
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  src={require("../../Assets/images/ABSYZ-White.png")}
                  alt="Absyz"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ height: "50px", marginTop: "-70px" }}
                  src={require("../../Assets/images/Pre-sales-Presentations.png")}
                  alt="Absyz"
                />
              </div>
              <div className="contentlogin">
                <div style={{ padding: "10px" }}>
                  <TextField
                    id="outlined"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter the Email"
                    autoComplete="off"
                    value={values.email}
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ padding: "10px" }}>
                  <TextField
                    // className="input"
                    variant="outlined"
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Enter the password"
                    value={values.password}
                    error={errors.password ? true : false}
                    helperText={errors.password}
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </div>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    className="button-1"
                    // type="submit"
                    onClick={() => handlelogin(values)}
                  >
                    Login
                  </Button>
                </Box>

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button className="button-2">Forgot Password</Button>
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
