import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import "./createpresentation.css";
import CreatePPT from "./Create-ppt";
import axios from "axios";
import { array } from "yup";
import { useState } from "react";
import Reorder1 from "./Reorder";
import { useLocation, useNavigate } from "react-router-dom";
import Ec2Url from "../../../api/Service";
import { getPpts } from "../../../Endpoints/Endpoints";

const FullWidthTabs=(props)=>{
  const token=JSON.parse(localStorage.getItem('Token'));
  const AuthStr = 'Bearer '+token['token'];
  const [pptData,setPptData]=React.useState([])
  const [slidesData,setSlidesData]=React.useState([]);
  const [savedArray,setSavedArray]=useState([]);
  const [savedTitle,setSavedTitle]=useState([]);
  const [savedSection,setSavedSection]=useState([]);
  const [finalArray,setFinalArray]=useState([]);
  const [clear,setClear]=useState(false);
  const [status,setStatus]=useState(false);
  const [slideIndex,setSlideIndex]=useState(0);
  const [slideValue,setSlideValue]=useState(0);
  const [slide_id,setSlide_id]=useState(49);
  const [tsIndex,setTsIndex]=useState(0);
  const [ts_id,setTs_id]=useState(55);
  const [slide_header,setSlide_header]=useState(0);
  const [userId,setUserId]=useState();
  const {state}=useLocation();
  const navigate=useNavigate();
  //const [lastTab,setLastTab]=useState();
  React.useEffect(()=>{
    FetchPPT();
    setUser();
    sessionStorage.setItem("Title_Deck.pptx.pptx","false");
    sessionStorage.setItem("section_slides.pptx","false");
  },[])
  const setUser=(()=>
  {
     setUserId(state.udata);
  })
  const FetchPPT=()=>
  {
    // console.log(AuthStr,"InEffect2")
    axios.get(`${Ec2Url}${getPpts}`,{
          headers: {
              'Content-Type': 'application/json',
              'Authorization': AuthStr
          },      
      }).then((response) => {
        // console.log('response',response.data)
        // console.log(response.data['result'])
        const data1=response.data['result'].filter((d)=>d.id!=3 && d.id!=57);
        setPptData(response.data['result'].filter((d)=>d.id!=3))
        // console.log(pptData,"PPT DATA")
      })
      .catch((error) => {
         console.log(error.response.data,'error23')
         if(error.response.data.message==="Token Expired")
          {
             navigate("/");
          }
      })
  }

  if(clear===true)
  {
    setFinalArray([]);
  }
//  console.log(finalArray,"finalin")

  return (
    <>{
      status===true?<Reorder1 finalArray={finalArray} setFinalArray={setFinalArray} status={status} setStatus={setStatus} userId={userId}/>:
      <CreatePPT pptData={pptData} finalArray={finalArray} setFinalArray={setFinalArray} status={status} setStatus={setStatus} savedArray={savedArray} setSavedArray={setSavedArray} 
       slideIndex={slideIndex}
       setSlideIndex={setSlideIndex}
       slideValue={slideValue}
       setSlideValue={setSlideValue}
       slide_id={slide_id}
       setSlide_id={setSlide_id}
       tsIndex={tsIndex}
       setTsIndex={setTsIndex}
       ts_id={ts_id}
       setTs_id={setTs_id}
       slide_header={slide_header}
       setSlide_header={setSlide_header}
       savedTitle={savedTitle}
       savedSection={savedSection}
       setSavedTitle={setSavedTitle}
       setSavedSection={setSavedSection}
      />
      
    }
    </>
  );
}
export default FullWidthTabs;