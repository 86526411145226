import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "./footer.css";

export default function Footer() {
  return (
    <Paper sx={{ bottom: 0}} component="footer" square variant="outlined" className="footer">
      <Container maxWidth="lg" style={{
            marginLeft: "0px"}}>
        <Box 
          sx={{
            flexGrow: 1,
            justifyContent: "left",
            display: "flex",
            my:1
          }}
        >
        </Box>

        <Box 
          sx={{
            flexGrow: 1,
            justifyContent: "left",
            display: "flex",
            mb: 2,
          }}
        >
          <Typography variant="caption" color="initial" className="footer-1" style={{color:"white"}}>
            © ABSYZ PRE-SALES 2022. 
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}
