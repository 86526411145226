import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { Button } from '@mui/material';
import Footer from "../../footer/Footer"
import {Form,MyForm} from "../../../components/MyForm/MyForm"
import { Email } from '@mui/icons-material';
import axios from 'axios';
import BASE_URL from '../../../api/Api';
import { signupUser } from '../../../Endpoints/Endpoints';
import { toast, ToastContainer } from 'react-toastify';
import Ec2Url from '../../../api/Service';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

function TabPanel(props) {


  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export  function AddUser() {
    const {state} = useLocation();
    console.log(state, "Use Nav data");
  const [auth, setAuth] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  const initialValues = {
    firstName : "",
    email : ""
  }
 
  const validate = (inputValues = values) => {
    console.log("firstname", inputValues.firstName)
    let code = { ...errors};
    if("firstName" in inputValues)
    code.firstName = inputValues.firstName ? "" : "Name is required";
    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if ("email" in inputValues)
      code.email = inputValues.email
        ? emailValidation.test(inputValues.email)
          ? ""
          : "Email is not valid"
        : "Email is required";
    setErrors({
      ...code,
    });
    //console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  }

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
  MyForm(initialValues, true, validate);

  async function handleCloseUserMenu() {
    navigate("/");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const backToslidesHandle = () =>
  {
    console.log("Handle cancel")
    if(state.userName){
     let userName=state.userName;
    navigate("/ViewAccount",{state:{userName}});
    }
  }
  const [name,setName]=useState();
  const [email,setEmail]=useState();
  const [password,setPassword]=useState('digital@absyz');
  //const notify = () => toast("User Added Successfully !");

  const notify = () => toast.success("User added successfully!",{icon: "🚀"});
  const notifyError = ()=>toast.error("Something went wrong !")
  const handleCreateuser = () =>{
    if (validate()) {
      let body =
     {
      "name":values.firstName,
      "email":values.email,
      "password":password
     }
     axios.post(`${Ec2Url}${signupUser}`,body).then(
      (Response)=>{
           console.log(Response.data);
           notify();
      },
      (error)=>{
          notifyError();
      }
    )
    }
  }

  const saveName = (e)=>{
    setName();
  }
  const saveEmail = (e)=>{
     setEmail(e.target.value);
  }
 
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header'>
    <span className='header-3'>Update</span>
    </div>
    <ToastContainer/>
    </AppBar>
    </ThemeProvider>
    </Box>
    <form></form>
    <div className='content-4'>
        <div className='form-div-1'>
            <div className='logo-wrapper-1'>
                <span style={{fontSize:"25px"}}>Add Pre-Sales User</span>
            </div>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          className='adminusername'
          id="outlined-required"
          type="full Name"
          label="Full Name"
          name="firstName"
          autoComplete="off"
          value={values.firstName}
          error={errors.firstName ? true : false}
          helperText={errors.firstName}
          onChange={ handleInputChange}
        />
      </div>
      <TextField
          id="outlined-password-input"
          label="Email"
          type="email"
          name="email"
          autoComplete="off"
          value={values.email}
          error={errors.email ? true: false}
          helperText={errors.email}
          onChange={handleInputChange}
        />
      {/* <div style={{height:"50px",display:"flex",justifyContent:"center", padding:"10px"}}> 
      <input
      name="upload-photo"
      type="file"
      accept='.pptx'
      />  
      </div>  */}
    </Box>
    <div style={{marginTop:"10px"}}>
        <Stack spacing={2} direction="row">
            <Button
            variant="contained"
            className="button-1"
            type="submit"
            onClick={handleCreateuser}
            style={{ textTransform:"capitalize"}}
            >
            Create
            </Button>
            <Button variant="contained"  onClick={backToslidesHandle} style={{ textTransform:"capitalize",backgroundColor:"black",width:"50%",fontWeight:"500",fontSize:"14px"}}>
            Cancel
            </Button>
        </Stack>
    </div>
        </div>
    </div>
    <Footer/>
    </>
  );
}