import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import "./createppt.css";
//import "./Create_Presentation.css";
import Checkbox from '@mui/material/Checkbox';
import { array } from 'yup';
import { makeStyles } from '@mui/styles';
import { Notes } from './Notes';
import { useState } from 'react';
import { useEffect } from 'react';
import backgroundimage from "../../../Assets/images/background-10.png"


const Title_Info =["Pre-Sales",'Assisted Proposal Creation'];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


function TabPanel(props) {
  const { children, value, index, ...other } = props;

 

  return (
    <div className='tabpanel-1'
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export default function VerticalTabs(props){
  const [value, setValue] = useState(props.slideVal);
  //console.log(props.slidedata,"Check for props")
  const getSource = (e,val)=>{
    console.log(val);
  }
  const {Id,file,presentation,myRef,setPresentation,slideVal,setSlideVal,ChangeButtonStatus} =props;
  const [present,setPresent]=useState(presentation);
  useEffect(()=>{
   
    console.log(present,"Selected Present");
    myRef.current.push(present);
    console.log(myRef,"My ref check")
  },[present])
  const handleChange = (event, newValue) => {
   // console.log(newValue, "value")
   
   setValue(newValue);
   //console.log(newValue,"Values");
  //  setSlideVal(newValue);
  //  setImage(newValue);
    //setValue(event.target);
  };
  //console.log(props.mp,"MAPPED");
  const [image_url,setImage_url]=React.useState();
  const [indexed,setIndex]=React.useState();
  const [slideId,setSlideId]=React.useState();
  const [status,setStatus]=React.useState();
  const {image,setImage}=props;
  const CheckboxHandle = (present,items)=>{
    
    var checkdata = false
    // slidesArray.map(
    //   (each) => { console.log(each.id,items.slideId,(each.id === items.slideId), 'item id'); 
    //   return each.id === items.slideId}
    // ).join()==='true'

    present.map((each) => {
      //console.log(each.imageUrl,items.imageUrl,(each.imageUrl === items.imageUrl), 'item id')
      return each.imageUrl !== items.imageUrl})

      present.forEach(each =>{
        if(each.imageUrl === items.imageUrl){
          checkdata = true
        }
      })
      return checkdata
      // forEach
  }
      const TabSlide=(image_url,slideid,i)=>
      {
         
           //setImage(i);
          // setStatus("ADD");
          // setImage_url(image_url);
          // setIndex(i);
          // setSlideId(id);
          // props.present?.map((each)=>{
          //    if(each.slideid===id )
          //      props.present =props.present.filter()
          // })
          // let array = [
          //   ...present,
          //   {
          //     id: Id,
          //     name:file,
          //     imageUrl: image_url,
          //     slides:[i+1]
          //   },
          // ];
        
          let RNumber = (Math.floor(1000 + Math.random() * 9000)).toString();
          let array1 = [
            ...present,
            {
              unique:slideid,
              id: Id,
              name:file,
              imageUrl: image_url,
              slides:[i+1]
            },
          ];
          if (present.length > 0) {
            present.map((each) => {
              if (each.imageUrl === image_url) {
                array1 = array1.filter((eacharr) => eacharr.imageUrl !== image_url);
              }
            });
          }
      setPresent(array1)
      // setPresentation(array1)
      //  props.addSlide(image_url,indexed,slideId,status);
      }
    
  // const checkHandler = (imageUrl,val)=>{
  //        if(val==1)
  //        {
  //         localStorage.setItem("title",imageUrl);
  //        }
  //        if(val==2)
  //        {
  //         localStorage.setItem("title","none");
  //        }
  // }
  
  //console.log(value, "imageValue")
  // console.log(props.slidedata,Y)
  // return <>
  //    <div className='assist-container'>
  //       <div className='slide-list'>
  //         {
  //           props.slidedata.map((items,index)=>(
               
  //             <div className='slide-div' style={{backgroundImage:`url(${items.imageUrl})`}}>
  //                <Checkbox checked={CheckboxHandle(presentation,items)} style={{marginLeft:"165px"}}{...label} color="primary" onChange={()=>{TabSlide(items.imageUrl,items.slideId,index)}}/>
  //             </div>
  //           ))
  //         }  
  //       </div>
  //       <div className='d-flex slide-presentation'>
  //       <div className='slide-open' ></div>
  //       </div>
  //    </div>
  // </>
  
  return <Box style={{backgroundImage: `url(${backgroundimage}`,backgroundSize:"cover"}}
  sx={{
    // flexGrow: 1,
    bgcolor: "black",
    display: "flex",
    //  height: 224
  }}
>
  <div className='main-div-1' style={{height:"100vh",
  // backgroundImage: `url(${backgroundimage}`,backgroundSize:"cover"
  }}>
  <Tabs
    orientation="vertical"
    variant="scrollable"
    scrollButtons="false"
    value={value}
    onChange={handleChange}
    aria-label="Vertical tabs example"
    sx={{ borderRight: 1, borderColor: "divider" }}
    className="tab_widthstyle"
    // style ={{height:"81vh", overflowY:"", display:"flex", backgroundColor:"rgba(66, 185, 197, 1)"}}
  >
    {props.slidedata.map((items, index) => (
      <Tab
        className="slide-list" 
        style={{cursor:"unset"}}
        icon={
          // /<div>
            <div
              className="slide-div"
              style={{ backgroundImage: `url(${items.imageUrl})` }}
               
              // onClick={()=>{console.log(index,"indexed")}}
            >
              <Checkbox
                checked={CheckboxHandle(present, items)}
                style={{ marginLeft: "160px", marginTop:"3px", backgroundColor:"white", height:"17px" , width:"17px"}}
                color="primary"
                onChange={(e) => {
                  // setCheckImage(items.imageUrl)
                  // console.log(e,"eee");
                  TabSlide(items.imageUrl, items.slideId, index);
                  ChangeButtonStatus()
                 
                }}
                // key={index}
                // {...a11yProps(index)}
              />
            {/* </div> */}
            {/* <img src={items.imageUrl} alt="image" width="200px" height="110px"></img> */}
          </div>
        }
        key={index}
        {...a11yProps(index)}
       
      />
    ))}
    
  </Tabs>
  </div>
  

 {/* { image=="undefined"? 
 props.slidedata.map((items, index) => (

  <TabPanel value={value} index={index}>
    {
      console.log(items.imageUrl,"imageUrl2")
    }
    <img
    alt="img"
      src={items.imageUrl}
      // className="tab-image"
      style={{
        height: "633px",
        marginLeft: "30px",
        marginTop: "20px",
        padding: "10px",
      }}
    />
  </TabPanel>
)) */}
 
{/* //  checkedArray.map((items, index) => 

  // console.log(checkedArray.length,"length")
   // console.log(image,"image_url")
 :   <div> */}
    <div className='tabshowbackground'>
    <div className = "background-1"style={{
    //  height:"81%",
    // width:"100%",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"center",
    }}>
      <div style={{height:"100%",width:"87%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <img
     src={props.slidedata[value].imageUrl}
     className="tab-image"
     style={{
      display:"flex",
      alignItems:'center',
      justifyContent:"center",
      width:"82%",
     }} 
     alt="image"
     />
      </div>
    <Notes notesData={props.slidedata[value].notes} />
    </div>
    </div>  
  


  {/* {props.slidedata.map((items, index) => (

    <TabPanel value={value} index={index}>
      <img
      alt="img"
        src={items.imageUrl}
        className="tab-image"
        style={{
          height: "633px",
          marginLeft: "30px",
          marginTop: "20px",
          padding: "10px",
        }}
      />
    </TabPanel>
  ))} */}


  
</Box>
}


  