import React from "react";
import TextField from "@mui/material/TextField";

export default function ReusableInputfield(props) {
  const {
    name,
    label,
    value,
    onChange,
    size,
    placeholder,
    maxLength,
    error = null,
    disabled,
    inputProps,
    rows,
    className,
    helperText,
    type,
    fullWidth,
    variant,
    format,
    minDate,
    maxDate,
    id,
    style,
  } = props;

  return (
    <TextField
      type={type}
      id={id}
      variant={variant}
      label={label}
      style={style}
      name={name}
      format={format}
      value={value}
      onChange={onChange}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      inputProps={inputProps}
      autoComplete="off"
      className={className}
      error={error}
      helperText={helperText}
      minDate={minDate}
      maxDate={maxDate}
      // multiline
      rows={rows}
      fullWidth={fullWidth}
      maxLength={maxLength}
    />
  );
}
