import Login from './components/Login/Login';
import {ProtectedAdminRoute , ProtectedRoute} from "../src/components/Login/layout"
import { Home } from './components/Login/Home';
import './App.css';
import {Routes,Route, Router} from "react-router-dom"
import { Admin } from './components/Login/admin';
import FullWidthTabs from './components/user/Presentation/Presentation';
import { useState } from 'react';
import ViewFiles from './components/user/ViewFiles/ViewFiles';
import {ManagePresentation} from './components/Admin/Managepresentation/ManagePresentation'
import {SlidePresentation} from './components/Admin/Managepresentation/SlidePresentation'
import {AddSlides} from './components/Admin/Managepresentation/AddSlides'
import { UserManagement } from './components/Admin/UserManagement/UserManagment';
import { AddUser } from './components/Admin/UserManagement/AddUser';
import ManageCvs from './components/Admin/Managepresentation/ManageCvs';
import AddCv from './components/Admin/Managepresentation/AddCv';
import ViewCvfromAdmin from './components/Admin/Managepresentation/ViewCv';
import Viewcv from './components/user/Viewcv/Viewcv';
import ViewCvfromUser from './components/user/Viewcv/DocviewByuser';
import Home2 from './components/Login/Home2'



function App() {
  
  const [userId,setUserId]=useState();
  // const id=sessionStorage.getItem("id");
  // console.log(id,"inAppjs");
  return (
    <div>
      
         <Routes>
          <Route exact path="/" element={<Login setUserId={setUserId}/>}/>

            <Route element={<ProtectedRoute/>}>
              
             <Route exact path="/User" element={<Home2/>}>
             </Route> 
             <Route path="/presentation" element={<FullWidthTabs />}></Route>
             <Route path="/ViewFiles" element ={<ViewFiles />}></Route>
              {/* { userId!==undefined ?
               <Route path="/presentation" element={<FullWidthTabs  userId={userId}/>}></Route>:
               <Route path="/presentation" element={<FullWidthTabs  userId={id}/>}>  </Route>
              } */}
              
             {/* {
              userId!==undefined ?
             <Route path="/ViewFiles" element ={<ViewFiles userId={userId}/>}></Route> :
             <Route path="/ViewFiles" element ={<ViewFiles userId={id}/>}></Route>
             } */}

            <Route exact path="/View-cvs" element={<Viewcv/>}></Route>
            <Route name="/user-doc" exact path="/user-doc" element={<ViewCvfromUser/>}></Route>

            </Route> 

            <Route element={<ProtectedAdminRoute/>}>
            <Route exact path="/Admin" element={<Admin/>}></Route>
            <Route exact path="/admin-landing/slides-list" element={<ManagePresentation/>}></Route>
            <Route exact path="/slidepresenter" element={<SlidePresentation/>}></Route>
            <Route exact path="/Add-Slide" element={<AddSlides/>}></Route>
            <Route exact path="/To-Cvs" element={<ManageCvs/>}></Route>
            <Route exact path="/addcvs" element={<AddCv/>}></Route>
            <Route name="/docview" exact path="/docview" element={<ViewCvfromAdmin/>}></Route>

            <Route exact path="/ViewAccount" element={<UserManagement/>}> </Route>
            <Route exact path="/Add-User" element={<AddUser/>}></Route>
          </Route>
        </Routes> 
    </div>
  );
}

export default App;