import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import './notes.css'
import edit from "../../../Assets/images/edit.png"

export function Notes({notesData}){
  //console.log(notesData.split(".")[0],"Check notes data")
  let array = notesData.split(".")
 // console.log(array[1], "Splited array")
  
    
    const GetNotes = (notesData) =>{
      // console.log(notesData.notesData.length,"Check notes data length")
      //   if(notesData.notesData.length > )
      //   {
      //   notesData.notesData.map((notesData,index)=>{
      //     {console.log(notesData,"Final inside li")}
      //     return <li key={index}>{notesData}</li>  ;
      //   })
      // }
      //     else{
      //   return <li style={{marginTop:"200px",fontSize:"12px"}}>{"This Slide does not contains any Notes"}</li>
      //     }  
      return(
        <div>
        {notesData.notesData.length > 1 ?
        <>
        {notesData.notesData.map((notedata,index)=>{
          return <li style={{textAlign:"justify",letterSpacing:"0px"}}>{index+1}.<span>{notedata}</span></li>  
        })}
        </>:
        <li style={{marginTop:"200px",fontSize:"12px",paddingLeft:"35px" }}>{"This Slide does not contains any Notes"}</li>}
        </div>
      )
    }
  
    return(
        <div className="notes-expand">
        <div className="notes-header">
          {/* <SpeakerNotesIcon style={{color:"white"}}/> */}
          <img src={edit} alt=""></img>
        </div>
        <div className="content-notes">
            <ol>  
                <GetNotes notesData={array}/> 
            </ol>
        </div>
      </div>
    )
}