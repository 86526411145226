import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../../api/Api';
import { fetchCvs } from '../../../Endpoints/Endpoints';
import Ec2Url from '../../../api/Service';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';  
import backgroundimage from "../../../Assets/images/background-10.png"
import Logout from '../../Logout/logout';
import "./Viewcv.css"
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import backgroundTopImg from "../../../Assets/images/Rectangle-1.png"


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
});

const data = [{
  id : 1,
  name : "saurabh",
  url : '/Assets/absyz_blue.png'
},
{
  id : 2,
  name : "phani",
  url : '/Assets/absyz_blue.png'
},
{
  id : 3,
  name : "pranaya",
  url : '/Assets/absyz_blue.png'
},
{
  id : 4,
  name : "nadeem",
  url : '/Assets/absyz_blue.png'
},
{
  id : 5,
  name : "prem",
  url : '/Assets/absyz_blue.png'
},
{
  id : 6,
  name : "abhishek",
  url : '/Assets/absyz_blue.png'
},
{
  id : 7,
  name : "saran",
  url : '/Assets/absyz_blue.png'
},
{
  id : 8,
  name : "santhosh",
  url : '/Assets/absyz_blue.png'
},
{
  id : 9,
  name : "vamshi",
  url : '/Assets/absyz_blue.png'
},
{
  id : 10,
  name : "sravan",
  url : '/Assets/absyz_blue.png'
},
]
const columns = [
  { id: 'ID', label: 'S/N.', minWidth: 100 ,align: 'center'},
  { id: 'FileName', label: 'File Name', minWidth: 100,align: 'center' },
  // { id: 'Action1', label: 'View', minWidth: 100, align: 'center' },
  { id: 'Action', label: 'Action', minWidth: 100 , align:"center"},
];
const rows = [
  { id:1,filename: 'Phanindra Verma',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/admitCard.pdf'},
  { id:2,filename: 'Abhishek Poojari',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
  { id:3,filename: 'Mohmaad Nadeem',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
  { id:4,filename: 'Saurabh Sah',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
  { id:5,filename: 'Sravan Naikini',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
  { id:6,filename: 'Premsagar Macharla',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
  { id:7,filename: 'Kesav Chaitanya',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'}
];

export default function Viewcv() {

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const {state} = useLocation();
  const navigate = useNavigate();
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [cvs,setCvs]=useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
   
  useEffect(()=>{
    getAllCvs();
  },[])

  const token=JSON.parse(localStorage.getItem('Token'));
   const AuthStr = 'Bearer '+token['token'];
   const getAllCvs = ()=>
   {
      axios.get(`${Ec2Url}${fetchCvs}`,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': AuthStr
        },      
    }).then((response) => {
       console.log(response.data.result,"allcvs");
       setCvs(response.data.result);
    })
    .catch((error) => {
       console.log(error);
       if(error.response.data.message==="Token Expired")
       {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
       }
    })
   }

  // const [information,setInformation] = React.useState(props.Info);
// console.log(information,"information");

    // const [information,setInformation] = React.useState(props.Info[0]);
    // const [subinformation,setSubInformation] = React.useState(props.Info[1]);

  // console.log(props.Info.subtitle[1],"information");
  // const settings = [ 'Logout'];

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleCloseUserMenu() {
    setAnchorElUser(null);
    //localStorage.clear();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
    
  const userLogout =()=>
  {
    setAnchorElUser(null);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }
  // console.log(props, "doctorsList");
  // const [titleInfo, settitleInfo] = useState();
    
  // {
  //   props.map((each)=>
  //   {
  //     return(
  //       <>
  //       {each.title}
  //       {each.subtitle}
  //       </>
  //     )
  //   })
  //  }
  const handleToHomePage = (event) =>
      {
        navigate("/User");
    }
  const onDownload = (fileLink) => {
    const link = document.createElement("a");
    // link.download = finalFile;
    link.href = fileLink;
    link.click();
  };
 
  const handleDocView = (data) =>{
    // localStorage.setItem("cvuser",data);
   // window.open("/user-doc","_blank")
    navigate("/user-doc", { state: {data} })
  }
  
return (<div style={{height:"100vh"}}>
        <Box sx={{ flexGrow: 1 }}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary" enableColorOnDark>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <div className="logo" style={{ display: "flex" }}>
                    <div className="logo-div">
                      <img
                        className="absyzimg image"
                        style={{ height: "50px",marginBottom:"5px" }}
                        src={require("../../../Assets/images/absyz_blue.png")}
                        alt="Absyz"
                      />

<div className="logo-text">
                        <p
                          className="pre-salestext"
                          style={{ marginTop: "5px", marginBottom: "-10px", color: "rgba(11, 38, 99, 1)",fontSize:"20px",fontWeight:"600" }}
                        >
                          Pre-sales
                        </p>
                        <span
                          className="assistented-proposalText"
                          style={{ color: "#3e3e3c", fontSize:"14px",lineHeight:"21px",fontWeight:"400"}}
                        >
                          Assisted proposal creation
                        </span>
                      </div>

                    </div>
                    {/* <Box sx={{ flexGrow: 0 , marginTop:"10px"}}>
                        <Tooltip title="Open settings">
                          <IconButton
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0 }}
                          >
                         <Avatar style={{color:"cornflowerblue", backgroundColor:"white"}} alt="Remy Sharp" src="" />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: "45px" }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          <MenuItem >
                            <Typography textAlign="center">{state.uname}</Typography>
                          </MenuItem>
                          <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                            <Typography textAlign="center">
                              <Button style={{backgroundColor:'black'}}onClick={()=>{userLogout()}}>Logout&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box> */}
                      <Logout/>
                  </div>
                </Typography>
               
              </Toolbar>
            </AppBar>
          </ThemeProvider>
          
        </Box>
        <Box classname='main-div' style={{height:"100%",backgroundImage: `url(${backgroundimage})`,backgroundSize:"cover",width:"100%",justifyContent:'center',alignItems:"center"}}>  
        <div
        style={{
          height: "7%",
          width: "100%",
          // backgroundColor: "rgba(66, 185, 197, 1)",
          display: "flex",
          alignItems: "center",
          backgroundImage: `url(${backgroundTopImg})`
        }}
      >
        <div className="element_style">
          <Button
            className="button"
            style={{ cursor: "pointer",textTransform:"capitalize",fontSize:"10px",fontFamily:"unset"}}
            onClick={handleToHomePage}
          >
            <ArrowBackIosIcon style={{height:'16px',marginTop:"2px"}}/>
            Back
          </Button>
          <div
            className="text"
            // style={{ width:"100%",display:"flex",justifyContent:"center" }}
          >
            View CVs
          </div>
        </div>
      </div>
      <Box style={{height:"90%",display:"flex",justifyContent:"center",alignItems:"center"}} >
      <Box >
        {/* ------------------------------------------------------------- */}
        <div className="login-box">
     
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table" className='headchange'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, display: column.display, justifyContent:column.justifyContent,color:"white",
                  backgroundColor:"rgb(23 45 84)"}}
                >
                  {column.label}
                </TableCell>
                
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
         
         {cvs
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

          .map((row,index)=>(
              <TableRow  key={index} className= "row-Styles"
               sx={{ '&:last-child td, &:last-child th': { border: 0 },padding:"8px" }}>
                   <TableCell align="center" component="th" scope="row" style={{color:"white"}}>{index+1}</TableCell>
                   <TableCell align="center" style={{marginRight:"40px",color:"white"}} ><span variant='text' style={{textTransform:"capitalize",color:"white"}} >{row.filename}</span></TableCell>
                   <TableCell align="center"><VisibilityOutlinedIcon onClick={()=>{handleDocView(row.cvurl)}}style={{color:"white",cursor:"pointer",marginRight:"15px"}}></VisibilityOutlinedIcon>
                   <SaveAltIcon style={{color:"white",cursor:"pointer"}} onClick={()=>{onDownload(row.cvurl)}} /></TableCell>
                   {/* <TableCell align="center">
                    <SaveAltIcon style={{color:"white",cursor:"pointer"}} onClick={()=>{onDownload(row.cvurl)}} />
                   </TableCell> */}
              </TableRow>   
          ))
         }
         
    </TableBody>
        </Table>
      </TableContainer>
      <TablePagination style={{backgroundColor:"rgb(23 45 84)", color:"white"}}
        rowsPerPageOptions={[5,10, 25, 100]}
        component="div"
        count={cvs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </Box>
    </Box>
    </Box>  
      </div>
  
  );
}


