import React, { useState } from "react";

export function MyForm(initialValues, validateOnEdit = false, validate) {
  console.log(initialValues, "initialmyform")
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const jobpos = values.jobpos;
  
  const handleInputChange = (e) => {
    console.log(values);
    console.log(e, "lkjkhk");
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
    // checkDropdownValue
  };

  const handleValueChange = (e) => {
    console.log(e);
    console.log(values);
    if (e !== null) {
      const { value, label } = e;
      setValues({
        ...values,
        jobpos: value,
      });

      if (validateOnEdit) {
        validate({ jobpos: value });
      }
    }
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    handleValueChange,
  };
}


export function Form(props) {
  const classes = [];
  const { children, ...other } = props;
  return (
    <form className={classes.main} autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}