import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import './managepresentation.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect } from "react";
import axios from "axios";
import BASE_URL from "../../../api/Api";
import { fetchCvs } from "../../../Endpoints/Endpoints";
import Footer from "../../footer/Footer";
import Ec2Url from "../../../api/Service";
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });
  const rows = [
    { filename: 'Phanindra Verma',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/admitCard.pdf'},
    { filename: 'Abhishek Poojari',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
    { filename: 'Mohmaad Nadeem',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
    { filename: 'Saurabh Sah',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
    { filename: 'Sravan Naikini',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
    { filename: 'Premsagar Macharla',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'},
    { filename: 'Kesav Chaitanya',cvurl:'https://absyz-presales.s3.amazonaws.com/cv/Saurabh%20Sah_Backend%20Developer_Absyz%20_.docx'}
  ];
const ManageCvs = ()=>
{

    const [rowdata,setRowdata]=useState([]);
    const [auth, setAuth] = useState(true);
    const [anchorElUser, setAnchorElUser] = useState();
    const {state} = useLocation();
    const navigate = useNavigate();
    const [cvs,setCvs]=useState([]);

    async function handleCloseUserMenu() {
      setAnchorElUser(null);
    };
   useEffect(()=>{
      getAllCvs();
   },[])
   const token=JSON.parse(localStorage.getItem('Token'));
   const AuthStr = 'Bearer '+token['token'];
   const getAllCvs = ()=>
   {
      axios.get(`${Ec2Url}${fetchCvs}`,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': AuthStr
        },      
    }).then((response) => {
       console.log(response.data.result,"allcvs");
       setCvs(response.data.result);
    })
    .catch((error) => {
       console.log(error);
    })
   }
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
   const userLogout =()=>
    {
      setAnchorElUser(null);
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  const goToprevPage = ()=>
  {
     navigate('/admin-landing/slides-list');
  }
  const toAddCvPage = ()=>
  {
    if(state.userName)
    {
      let userName=state.userName;
      navigate('/addcvs',{state:{userName}});
    }
    
  }
  const handleDocView = (data) =>{
    localStorage.setItem("cvurl",data);
    window.open("/docview","_blank")
    // navigate("/docview", { state: {data} })
  }
  const onDownload = (fileLink) => {
    const link = document.createElement("a");
    // link.download = finalFile;
    link.href = fileLink;
    link.click();
  };
  return <>
     <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px",fontSize: "16px",fontWeight: "600"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{color:"black", backgroundColor:"transparent"}} alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem style={{display: "flex", justifyContent:"center"}}>
               <Typography textAlign="center">{state.userName}</Typography>
               </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                  <Typography textAlign="center">
                  <Button style={{backgroundColor:'black',color:"white",borderRadius:"4px"}}onClick={()=>{userLogout()}}>LOGOUT&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                    </Typography>
                </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header'>
    <div style={{width:"50%"}}><Button className='Button-butter'onClick={goToprevPage}>back</Button></div>
    <div style={{width:"50%",display:"flex",justifyContent:"flex-end"}}><Button className='button-text3' onClick={toAddCvPage}>Add CV</Button></div>
    </div>
    </AppBar>
    </ThemeProvider>
    </Box>
    <Box className="manage-cv-box">  
      <Box style={{height:"60%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
    <div style={{width:"100%", display:"flex",justifyContent:"center"}}><span className='header-1'>All Fetched Cvs</span></div>
    <div className='content-1'>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S/N</TableCell>
            <TableCell align="center">File Name</TableCell>
           
            <TableCell align="center">Action</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cvs?.map((row,index) => (
            <TableRow
            //   key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell align="center">{row.filename}</TableCell>
              <TableCell align="center" style={{padding:"10px", textTransform:"capitalize"}}>
                <Button onClick={()=>{handleDocView(row.cvurl)}} className='Button-butter-1'>View</Button>
              </TableCell>
              <TableCell align="center" style={{padding:"10px", textTransform:"capitalize"}}><Button onClick={()=>{onDownload(row.cvurl)}} className='Button-butter-1'>Download</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </Box>
    </Box>
    <Footer/>
  </>
    
}

export default ManageCvs;