import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import "./Navigation.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { decryptData } from "../SecureLocal/Utill";
import Logout from "../Logout/logout";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
  },
});

export default function MenuAppBar(props) {
  useEffect(() => {
    username();
  }, []);
  const [userName, setUserName] = useState("");
  const username = () => {
    let mkLocalData = sessionStorage.getItem("data");
    if (!mkLocalData) {
    }
    const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
    let originalData = decryptData(mkLocalData, salt);
    //console.log(originalData,"originalData");
    setUserName(originalData.uname);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" color="primary" enableColorOnDark>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <div className="logo" style={{ display: "flex" }}>
                <div className="logo-div">
                  <img
                    className="absyzimg image"
                    style={{ height: "50px", marginBottom: "10px" }}
                    src={require("../../Assets/images/absyz_blue.png")}
                    alt="Absyz"
                  />
                  <div className="logo-text">
                    <p
                      className="pre-salestext"
                      style={{
                        marginTop: "5px",
                        marginBottom: "-10px",
                        color: "rgba(11, 38, 99, 1)",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Pre-Sales
                    </p>
                    <span
                      className="assistented-proposalText"
                      style={{
                        color: "#3e3e3c",
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                      }}
                    >
                      Assisted Proposal Creation
                    </span>
                  </div>
                </div>
              </div>
            </Typography>
            <Logout />
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
}
