import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./createppt.css";
import VerticalTabs from "./TabShow";
import { Checkbox, Grid } from "@mui/material";
import VerticalTabs2 from "./TitleSectionShow";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../../Endpoints/Endpoints";
import Ec2Url from "../../../api/Service";
import { createContext } from "react";
import SECRET_KEY from "../../SecureLocal/Secretkey";
import CryptoJS from "crypto-js";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
  },
});

const CreatePPT = (props) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const { pptData } = props;
  const [slideData, setSlidData] = useState([]);
  const [slideData2, setSlidData2] = useState([]);
  const [slides, setSlides] = useState([]);
  const [slides2, setSlides2] = useState([]);
  const [aboutAbsyz, setAboutAbsyz] = useState([]);
  const [value, setValue] = useState(props.slideValue);
  const [index, setIndex] = useState(props.slideIndex);
  const [valuet2, setValuet2] = useState(props.tsIndex);
  const [index2, setIndex2] = useState(props.tsIndex);
  const [value3, setValue3] = React.useState(0);
  const [image, setImage] = useState(0);
  const [image2, setImage2] = useState(0);
  const [image3, setImage3] = useState(0);
  const [titleVal, setTitleVal] = useState(0);
  const [sectionVal, setSectionVal] = useState(0);
  const [presentation, setPresentation] = useState(props.savedArray);
  const [title, setTitle] = useState([]);
  const [section, setSection] = useState([]);
  const [titleCheck, setTitleCheck] = useState(false);
  const [sectionCheck, setSectionCheck] = useState(false);
  const Navigate = useNavigate();
  const {
    finalArray,
    setFinalArray,
    status,
    setStatus,
    savedArray,
    setSavedArray,
    slideIndex,
    setSlideIndex,
    slideValue,
    setSlideValue,
    slide_id,
    setSlide_id,
    tsIndex,
    setTsIndex,
    ts_id,
    setTs_id,
    slide_header,
    setSlide_header,
    savedTitle,
    savedSection,
    setSavedTitle,
    setSavedSection,
  } = props;
  const myRef = useRef([]);
  const titleRef = useRef([]);
  const sectionRef = useRef([]);
  // console.log(titleRef,sectionRef,"Title and section ref")

  const retrievedRole = localStorage.getItem("Mainrole");
  const bytesRole = CryptoJS.AES.decrypt(retrievedRole, SECRET_KEY);
  const buttonrole = JSON.parse(bytesRole.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    if (finalArray.length > 0) {
      setInitialList();
    } else {
      setInitialTitleSection(55);
      setInitialTitleSection(56);
    }
    if (savedArray.length > 0) {
      setPresentation(savedArray);
    }
    handleOnClick(props.slide_id, slideIndex);
    handleOnClick2(props.ts_id, tsIndex);
  }, []);
  const setInitialList = () => {
    let flag = true;
    //console.log(finalArray,"Aboutabsy");
    finalArray?.forEach((element) => {
      let array = {
        unique: element.unique,
        id: element.id,
        name: element.name,
        imageUrl: element.imageUrl,
        slides: element.slides,
      };
      if (element.id === 142) {
        let array2 = [...title, array];
        setTitle(array2);
      }
      if (element.id === 143 && flag) {
        let array2 = [...section, array];
        setSection(array2);
        flag = false;
      }
    });
  };

  const setInitialTitleSection = (id) => {
    axios(`${Ec2Url}${getCategories}${id}`, {
      method: "GET",
    })
      .then(function (response) {
        //console.log(response.data,"responsedata")

        let array = [
          {
            unique: response.data["result"][0]["slides"][0].slideId,
            id: response.data["result"][0].id,
            name: response.data["result"][0]["filename"],
            imageUrl: response.data["result"][0]["slides"][0].imageUrl,
            slides: [1],
          },
        ];
        if (id === 55 && title.length === 0) {
          //console.log(array,"data is coming");
          setTitle(array);
        }
        if (id === 56 && section.length === 0) {
          setSection(array);
          // console.log(array,"data is coming");
        }
        //console.log(slideData,"slides-data")
      })
      .catch(function (e) {
        console.log(e);
      });
  };
  const handleOnClick = (id, ind) => {
    //  myRef.current.link.click();
    if (myRef.current[myRef.current.length - 1]) {
      // let arrayToEdit = []
      // arrayToEdit=[...presentation,myRef.current[myRef.current.length-1]];
      setPresentation(myRef.current[myRef.current.length - 1]);
      // setPresentation(arrayToEdit);
    }
    axios(`${Ec2Url}${getCategories}${id}`, {
      method: "GET",
    })
      .then(function (response) {
        // console.log(response.data,"responsedata")
        const alldata = {
          id: response.data["result"][0].id,
          // category_name:pptData.id.categoryname,
          category_id: response.data["result"][0]["categoryId"],
          file_name: response.data["result"][0]["filename"],
          slides: response.data["result"][0]["slides"],
          visibleName: response.data["result"][0]["visibleName"],
        };
        setSlides(response.data["result"][0]["slides"]);
        setSlidData(alldata);
        setIndex(ind);
        setSlideIndex(ind);
        setSlide_id(id);
        if (slides.length >= image || slides.length >= slideVal) {
          setImage(0);
          setSlideVal(0);
        }

        //  console.log(slideData,"slides-data")
      })
      .catch(function (e) {
        console.log(e);
      });
  };
  console.log(presentation, "data is adding!");
  const handleOnClick2 = (id, ind) => {
    if (titleRef.current[titleRef.current.length - 1]) {
      setTitle(titleRef.current[titleRef.current.length - 1]);
    }
    if (sectionRef.current[sectionRef.current.length - 1]) {
      setSection(sectionRef.current[sectionRef.current.length - 1]);
    }
    axios(`${Ec2Url}${getCategories}${id}`, {
      method: "GET",
    })
      .then(function (response) {
        //console.log(response.data,"responsedata")
        const alldata = {
          id: response.data["result"][0].id,
          // category_name:pptData.id.categoryname,
          category_id: response.data["result"][0]["categoryId"],
          file_name: response.data["result"][0]["filename"],
          slides: response.data["result"][0]["slides"],
          visibleName: response.data["result"][0]["visibleName"],
        };
        setSlidData2(alldata);
        setSlides2(response.data["result"][0]["slides"]);
        setIndex2(ind);
        setTsIndex(ind);
        setTs_id(id);
        if (slides2.length >= image2 || slides2.length >= titleVal) {
          setImage2(0);
          setTitleVal(0);
        }
        if (slides2.length > image3 || slides2.length > sectionVal) {
          setImage3(0);
          setSectionVal(0);
        }
        //console.log(slideData,"slides-data")
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const arrangeSlidesOrder = () => {
    let bulkSets = [];
    let arrayToEdit = [];
    if (myRef.current[myRef.current.length - 1]) {
      setSavedArray(myRef.current[myRef.current.length - 1]);
      arrayToEdit = [...myRef.current[myRef.current.length - 1]];
    } else {
      arrayToEdit = [...presentation];
    }
    let uniqueIds = [];
    let bulkIndividual = [];
    for (let i = 0; i < arrayToEdit.length; i++) {
      let uniqueElement = arrayToEdit[i].id;
      uniqueIds.push(uniqueElement);
    }
    uniqueIds = [...new Set(uniqueIds)];
    uniqueIds = uniqueIds.sort();

    for (let j = 0; j < uniqueIds.length; j++) {
      for (let k = 0; k < arrayToEdit.length; k++) {
        if (arrayToEdit[k].id === uniqueIds[j]) {
          bulkIndividual.push(arrayToEdit[k]);
        }
      }
      bulkSets.push(bulkIndividual);
      bulkIndividual = [];
    }
    let title_in = [];
    let section_in = [];
    if (titleRef.current[titleRef.current.length - 1]) {
      title_in = [...titleRef.current[titleRef.current.length - 1]];
    } else {
      title_in = [...title];
    }
    if (sectionRef.current[sectionRef.current.length - 1]) {
      section_in = [...sectionRef.current[sectionRef.current.length - 1]];
    } else {
      section_in = [...section];
    }
    const titleData = title_in[0];
    const sectionData = section_in[0];

    let serializedArray = [];
    let RNumber = Math.floor(100 + Math.random() * 9);
    serializedArray.push(titleData);
    for (let z = 0; z < bulkSets.length; z++) {
      let RNumber = Math.floor(1000 + Math.random() * 9000);
      let array1 = {
        unique: sectionData.unique + z,
        id: sectionData.id,
        name: sectionData.name,
        imageUrl: sectionData.imageUrl,
        slides: sectionData.slides,
      };
      serializedArray.push(array1);
      for (let y = 0; y < bulkSets[z].length; y++) {
        serializedArray.push(bulkSets[z][y]);
      }
    }
    if (serializedArray.length === 1) {
      serializedArray.push(sectionData);
    }
    // console.log(serializedArray, "serialized array")
    setFinalArray(serializedArray);
  };
  //  console.log(presentation,"presentations");
  const createBtnHandler = () => {
    if (myRef.current[myRef.current.length - 1]) {
      let arrayToEdit = myRef.current[myRef.current.length - 1];
      //arrayToEdit=[...presentation,myRef.current[myRef.current.length-1]];
      setPresentation(arrayToEdit);
      // setPresentation(arrayToEdit);
      console.log(myRef.current[myRef.current.length - 1], "final time");
    }
    if (titleRef.current[titleRef.current.length - 1]) {
      setTitle(titleRef.current[titleRef.current.length - 1]);
    }
    if (sectionRef.current[sectionRef.current.length - 1]) {
      setSection(sectionRef.current[sectionRef.current.length - 1]);
    }

    setSavedArray(presentation);
    setSavedTitle(title);
    setSavedSection(section);
    arrangeSlidesOrder();
    setStatus(true);
  };

  const handleTabs = (e, val) => {
    console.warn(val);
    setValue(val);
    setSlideValue(val);
  };

  const handleTabs2 = (e, val) => {
    console.warn(val);
    setValuet2(val);
  };
  const handleToHomePage = (event) => {
    Navigate("/User");
  };

  const theme = useTheme();
  const handleChange = (event, newValue) => {
    myRef.current = myRef.current;
    console.log(myRef, "My ref triggred on button click");
    if (myRef.current[myRef.current.length - 1]) {
      setPresentation(myRef.current[myRef.current.length - 1]);
    }
    if (titleRef.current[titleRef.current.length - 1]) {
      setTitle(titleRef.current[titleRef.current.length - 1]);
    }
    if (sectionRef.current[sectionRef.current.length - 1]) {
      setSection(sectionRef.current[sectionRef.current.length - 1]);
    }
    setValue3(newValue);

    //setSlide_header(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue3(index);

    //setSlide_header(index);
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const checkStatus = (title, section) => {
    if (section.length > 0 && title.length > 0) {
      return true;
    }
    return false;
  };
  const [slideVal, setSlideVal] = useState(0);

  const SlideShow = () => {
    return (
      <>
        {" "}
        {
          <Grid className="bg">
            <Tabs
              value={value}
              onChange={handleTabs}
              variant="scrollable"
              scrollButtons="false"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                id="tab"
                className={value === 0 ? "tap" : ""}
                label={"ABOUT ABSYZ"}
                onClick={() => handleOnClick(49, 0)}
              />
              ;
              <Tab
                id="tab"
                className={value === 1 ? "tap" : ""}
                label={"PROJECT IMPLEMENTATION"}
                onClick={() => handleOnClick(51, 1)}
              />
              ;
              <Tab
                id="tab"
                className={value === 2 ? "tap" : ""}
                label={"PROJECT MANAGEMENT"}
                onClick={() => handleOnClick(52, 2)}
              />
              ;
              <Tab
                id="tab"
                className={value === 3 ? "tap" : ""}
                label={"TEAM STRUCTURE"}
                onClick={() => handleOnClick(50, 3)}
              />
              ;
              {pptData.map((each, index) => {
                if (each.id > 52 && each.id <= 54) {
                  return (
                    <Tab
                      id="tab"
                      className={value === index ? "tap" : ""}
                      label={each.categoryname}
                      onClick={() => handleOnClick(each.id, index)}
                    />
                  );
                }
                if (each.id > 56) {
                  return (
                    <Tab
                      id="tab"
                      className={value === index - 2 ? "tap" : ""}
                      label={each.categoryname}
                      onClick={() => handleOnClick(each.id, index - 2)}
                    />
                  );
                }
              })}
            </Tabs>

            <TabPanel value={value} index={index}>
              {slides.length > 0 && (
                <VerticalTabs
                  slidedata={slides}
                  Id={slideData.id}
                  myRef={myRef}
                  file={slideData.file_name}
                  presentation={presentation}
                  setPresentation={setPresentation}
                  image={image}
                  setImage={setImage}
                  slideVal={slideVal}
                  setSlideVal={setSlideVal}
                  ref={myRef}
                />
              )}
            </TabPanel>
          </Grid>
        }
      </>
    );
  };

  const TitleSectionShow = () => {
    return (
      <>
        <Grid className="bg">
          {console.log()}
          <Tabs value={valuet2} onChange={handleTabs2}>
            {pptData.map((each, index) => {
              if (each.id >= 55 && each.id <= 56) {
                return (
                  <Tab
                    id="tab"
                    className={valuet2 === index - 6 ? "tap" : ""}
                    label={each.categoryname}
                    onClick={() => handleOnClick2(each.id, index - 6)}
                  />
                );
              }
            })}
          </Tabs>

          <TabPanel value={valuet2} index={0}>
            {slides2.length > 0 &&
            slides2.length !== image2 &&
            slides2.length !== titleVal ? (
              <VerticalTabs2
                slidedata={slides2}
                Id={slideData2.id}
                file={slideData2.file_name}
                myRef={titleRef}
                presentation={title}
                setPresentation={setTitle}
                image={image2}
                setImage={setImage2}
                slideVal={titleVal}
                setSlideVal={setTitleVal}
              />
            ) : (
              ""
            )}
          </TabPanel>
          <TabPanel value={valuet2} index={1}>
            {slides2.length > 0 &&
            slides2.length !== image3 &&
            slides2.length !== sectionVal ? (
              <VerticalTabs2
                slidedata={slides2}
                Id={slideData2.id}
                file={slideData2.file_name}
                myRef={sectionRef}
                presentation={section}
                setPresentation={setSection}
                image={image3}
                setImage={setImage3}
                slideVal={sectionVal}
                setSlideVal={setSectionVal}
              />
            ) : (
              ""
            )}
          </TabPanel>
        </Grid>
      </>
    );
  };
  return (
    <>
      <>
        <Box sx={{ flexGrow: 1 }}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary" enableColorOnDark>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <div
                    className="logo"
                    style={{ display: "flex", padding: "5px" }}
                  >
                    <div className="logo-div">
                      <img
                        className="absyzimg image"
                        style={{ height: "50px", cursor: "pointer" }}
                        src={require("../../../Assets/images/absyz_blue.png")}
                        alt="Absyz"
                        onClick={handleToHomePage}
                      />
                      <div className="logo-text">
                        <p
                          className="pre-salestext"
                          style={{
                            marginTop: "5px",
                            marginBottom: "-10px",
                            color: "rgba(11, 38, 99, 1)",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          Pre-Sales
                        </p>
                        <span
                          className="assistented-proposalText"
                          style={{
                            color: "#3e3e3c",
                            fontSize: "14px",
                            lineHeight: "21px",
                            fontWeight: "400",
                          }}
                        >
                          Assisted Proposal Creation
                        </span>
                      </div>
                    </div>
                  </div>
                </Typography>
                <Box className="outerbox">
                  <Tabs
                    selectionFollowsFocus
                    value={value3}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    className="header-tabs"
                    style={{ width: "200px" }}
                  >
                    <Tab label="Slides" {...a11yProps(0)} />
                    <Tab label="Headers" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <Stack spacing={2} direction="row" className="stack">
                  {checkStatus(title, section) ? (
                    <div style={{ cursor: "not-allowed" }}>
                      <Button
                        // disabled={buttonrole === 3}
                        variant="contained"
                        style={{
                          backgroundColor: "#62AF7B",
                          color: "white",
                          width: "100px",
                          height: "31px",
                          textTransform: "capitalize",
                          // cursor: "not-allowed"
                        }}
                        onClick={() => {
                          createBtnHandler();
                        }}
                        type="submit"
                      >
                        Create
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="secondary"
                      // disabled={buttonrole === 3}
                      style={{
                        backgroundColor: "#E4E4E4",
                        color: "white",
                        width: "100px",
                        height: "31px",
                        textTransform: "capitalize",
                        // cursor:"not-allowed"
                      }}
                      onClick={() => {
                        console.log(101, "disabled");
                      }}
                    >
                      Create
                    </Button>
                  )}
                </Stack>
              </Toolbar>
            </AppBar>
          </ThemeProvider>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value3}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel
              value={value3}
              index={0}
              dir={theme.direction}
              style={{ color: "black", marginBottom: "8px" }}
            >
              <SlideShow style={{ marginBottom: "8px" }} />
            </TabPanel>
            <TabPanel value={value3} index={1} dir={theme.direction}>
              <TitleSectionShow />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </>
    </>
  );
};

export default CreatePPT;
