const getPpts = "/getppts/getpptcategories";
const getCategories = "/getppts/getpptsbycategoryid?categoryid=";
const createMerge ="/createppt/mergeslides/";
const getUserFiles="/getppts/getUserFiles/";
const addCvs="/createcvs/addcvs";
const fetchCvs="/fetchCvs/getCvs";
const addPptTab="/createAdminppt";
const signupUser ="/auth/signup";
const getUsers="/user/getUsers";
const authenticate="/auth/authenticate"
export {
    getPpts,
    getCategories,
    createMerge,
    getUserFiles,
    addCvs,
    fetchCvs,
    addPptTab,
    signupUser,
    getUsers,
   authenticate,
};