import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useEffect } from "react";
import ProfilePic from "../../Assets/images/Group-54.png";
import { useNavigate } from "react-router-dom";
import "./logout.css";
import SECRET_KEY from "../SecureLocal/Secretkey";
import CryptoJS from "crypto-js";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0px",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setFirstName(localStorage.getItem("username").slice(1, -1));
  });
  //decrpted role ??
  const retrievedRole = localStorage.getItem("Mainrole");
  const bytesRole = CryptoJS.AES.decrypt(retrievedRole, SECRET_KEY);
  const roleChanges = JSON.parse(bytesRole.toString(CryptoJS.enc.Utf8));
  // const roleChanges = localStorage.getItem("mainrole");
  // if (roleChanges !== 1 || roleChanges !== 2 || roleChanges !== 3) {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   navigate("/");
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
  };

  const handleCloseMenuBar = () => {
    setAnchorEl(null);
  };

  return (
    // <div className="Admin-section-wrapper">
    <div
      style={{
        display: "flex",
        // width:"84%",
        justifyContent: "end",
      }}
      className="Logout-section-wrapper"
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{
          color: "rgba(11, 38, 99, 1)",
          fontSize: "16px",
          fontWeight: "700",
        }}
        startIcon={<img src={ProfilePic} alt="" width="24" height="24" />}
        endIcon={<KeyboardArrowDownIcon />}
        className="account-menu-button"
      >
        {firstName}
      </Button>

      <StyledMenu
        className="section"
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuBar}
      >
        <MenuItem
          disableRipple
          style={{
            background: "white",
            width: "180px",
            border: "none",
            padding: "0px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                textAlign: "center",
                background: "#F7F7F7",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px 14px 28px 14px",
                pointerEvents: "none",
              }}
            >
              <img
                src={ProfilePic}
                alt="profileIcon"
                width="30"
                height="30"
                style={{ marginTop: "6px" }}
              />
              <span
                className="profile-name-text"
                style={{
                  marginTop: "10px",
                  textTransform: "capitalize",
                  color: "rgba(11, 38, 99, 1)",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {firstName}
              </span>
              <span
                className="profile-role-text"
                style={{
                  marginTop: "8px",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {roleChanges === "1" ? "Admin" : "user"}
              </span>
            </div>

            <Button
              onClick={handleClose}
              style={{
                textAlign: "left",
                padding: "6px 10px",
                display: "flex",
                justifyContent: "center",
                color: "#02215B",
                fontWeight: "700",
                textTransform: "capitalize",
                // fontSize: "14px",
              }}
            >
              {/* <img
                src={logoutIcon}
                alt="logout logo"
                style={{ marginRight: "10px" }}
              /> */}
              Log Out
            </Button>
          </div>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
