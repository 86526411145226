import { values } from "underscore";
import { encryptData } from "../components/SecureLocal/Utill";
import BASE_URL from "./Api";
import Ec2Url from "./Service";

export async function apiIntegration(values) {
    
    let item = { email: values.email, password: values.password };
    console.log(item, "Check with final user data");
    // console.log(JSON.stringify(item), "Stringifyed data");
    const response = await fetch( Ec2Url +`/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });
    const result = await response.json();
    // console.log(result , "result")
    // localStorage.setItem("", JSON.stringify(result));
    if(result?.token){
    localStorage.setItem("Token", JSON.stringify({token:result.token}))
    //localStorage.setItem("role",JSON.stringify({role:result.user.role}))

    let data = JSON.stringify({role:result.user.role});
  
        const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
        const encryptedData = encryptData(data,salt);
        sessionStorage.setItem('role', encryptedData);
    }

    // navigate.push("/add")
    // console.log(result, "result");
    return result;
  };


