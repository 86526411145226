import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect } from "react";
import './managepresentation.css'
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { Button, Input } from '@mui/material';
import Footer from "../../footer/Footer"
import { addPptTab } from '../../../Endpoints/Endpoints';
import BASE_URL from '../../../api/Api';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Ec2Url from '../../../api/Service';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

function TabPanel(props) {


  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export  function AddSlides() {
    const {state} = useLocation();
    console.log(state, "Use Nav data");
  const [auth, setAuth] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  async function handleCloseUserMenu() {
    navigate("/");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const backToslidesHandle = () =>
  {
    console.log("Handle cancel")
    navigate("/admin-landing/slides-list");
  }
  const [file,setFile]=useState();
  const [filename,setFilename]=useState();
  
  const saveFilename=(e)=>
  {
    setFilename(e.target.value);
  }
  const saveFile = (e)=>
  {
    let fileName = e.target.files[0].name;
    var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    if(ext=="pptx" || ext=="PPTX")
    {
      setFile(e.target.files[0]); 
      console.log(ext,"pptx"); 


    }
    else
    {
      alert1();
    }
   
  }
  console.log(file,"file2");
  const addPpt = ()=>
  {
    
    if(filename && file)
    {
    let formData = new FormData();
    formData.append("categoryname", filename);
    formData.append("visibleName",filename);
    formData.append("pptFile",file);
    console.log(filename,"fileName");
    console.log(file,"fileinfunction");
    const token=JSON.parse(localStorage.getItem('Token'));
    const AuthStr = 'Bearer '+token['token'];
     axios.post(`${Ec2Url}${addPptTab}`,formData,{headers: {
      "Content-Type": "multipart/form-data",
    }}).then(
      (Response)=>{
           console.log(Response.data);
           notify();
      },
      (error)=>{
             console.log(error)
             notifyError();    
      }
    )
    console.log(formData.get("categoryname"),"formData");
    }
    else 
    {
    alert2();
    }
    
  }
   const alert1 = ()=>toast.warn("Enter Valid (.pptx)");
    const alert2= ()=>toast.warn("Enter valid details , Some of the field is missing", {
    });
    const notify = () => toast.success("Slide added successfully!",{icon: "🚀"});
    const notifyError = ()=>toast.error("Something went wrong !")

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem  onClick={handleCloseUserMenu}>
                    <Typography className='menuitem' textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header' style={{width:"100%", display:"flex",justifyContent:"center"}}>
    <span className='header-2'>Update</span>
    </div>
    </AppBar>
    <ToastContainer />
    </ThemeProvider>
    </Box>
    <form></form>
    <div className='content-3'>
        <div className='form-div-1'>
            <div className='logo-wrapper-1'>
                <span style={{fontSize:"25px"}}>Acceptable format (.pptx)</span>
            </div>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField

          className='adminusername'
          id="outlined-required"
          label="Tab Name"
          autoComplete="off"
          value={filename}
          onChange={saveFilename}
        />
      </div>
      {/* <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="new-password"
        /> */}
      <div style={{height:"50px",display:"flex",justifyContent:"center", padding:"10px"}}> 
      <Input name="upload-file" type="file" inputProps={{ accept: 'pptx' }}  onChange={saveFile}
      /> 
      </div> 
    </Box>
    <div>
        <Stack spacing={2} direction="row">
            <Button style={{ textTransform:"capitalize"}}
            variant="contained"
            className="button-1"
            onClick={addPpt}
            >
            Create
            </Button>
            <Button variant="contained" onClick={backToslidesHandle} style={{ textTransform:"capitalize",backgroundColor:"black",width:"50%",fontWeight:"500",fontSize:"14px"}}>
            Cancel
            </Button>
        </Stack>
    </div>
        </div>
    </div>
    <Footer/>
    </>
  );
}