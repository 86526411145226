import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "./style.css";
import Footer  from "../footer/Footer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "../Navigation/Navigation"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from '@mui/icons-material/Logout';
import { decryptData } from "../SecureLocal/Utill";

const data = [
  {
    id: 1,
    bg: "#54BAB9",
    heading: "Manage Presentations & Cvs",
    icon: faFilePowerpoint,
    description:
      "Create, add and edit cvs and presentation tabs which appear for users",
    button: "manage",
  },
  {
    id: 1,
    bg: "#B33030",
    heading: "User Management",
    icon: faUsers,
    description: "Add users, Block them temporarily from using the services, View the presentations created by them.",
    button: "View Account",
  },
  {
    id: 1,
    bg: "#1572A1",
    heading: "View File System",
    icon: faFolder,
    description: "Edit and Delete files from the portal. View User Data",
    button: "View Files",
  },
];

const Title_Info =['PRE-SALES ADMIN'];

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
});


export function Admin(props) {

  const [information,setInformation] = useState(Title_Info);
  const [anchorElUser, setAnchorElUser] = useState();
  const [auth, setAuth] = useState(true);
  const navigate = useNavigate();


  const Navigate = useNavigate()

  const navigation = (e) => {
    if(e.target.value === "manage"){
     
      Navigate("/admin-landing/slides-list",{state:{udata}})
    }else if(e.target.value === "View Account"){
      Navigate("/ViewAccount",{state:{userName}})
    }else if(e.target.value === "View Files"){
      Navigate("/Admin")
    }
  }
 useEffect(()=>{
  if(localStorage.getItem("Token").length<0)
  {
    Navigate("/");
  }
  if(!sessionStorage.getItem('data'))
    {
       
    }
    userData();

 },[])
  const [userName,setUserName]=useState("");
  const [udata,setUdata]=useState();
  const userData = ()=>{
    let mkLocalData = sessionStorage.getItem('data');
    if(!mkLocalData)
    {
         
    }
    const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
    let originalData = decryptData(mkLocalData, salt);
    console.log(originalData,"originalData1");
    setUserName(originalData.uname);
    setUdata(originalData);
    
  }
  async function handleCloseUserMenu() {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const userLogout =()=>
  {
    setAnchorElUser(null);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  
  return (
    <div style={{height:"100%"}}>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{color:"black", backgroundColor:"transparent"}} alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem style={{display: "flex", justifyContent:"center"}}>
               <Typography textAlign="center">{userName}</Typography>
               </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                  <Typography textAlign="center">
                  <Button style={{backgroundColor:'black'}}onClick={()=>{userLogout()}}>Logout&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                    </Typography>
                </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
        </Box>
    <Box  id="admin-h">
    <Grid
      sx={{ flexGrow: 1 }}
      style={{
        backgroundColor: "#F5F5F5",
        height: "100%",
        paddingTop: "0px",
        marginTop: "0px",
        overflow:"scroll",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} className="Admin-cards" style={{display:"flex",flexDirection:"column",justifyContent:'center'}}>
        <Grid
          container
          justifyContent="center"
          spacing={12}
          style={{ marginTop: "9px" }}
        >
          {data.map((value) => (
            <Grid key={value.id} item>
              <Paper
                sx={{
                  height: 400,
                  width: 330,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                }}
              >
                <Grid>
                  <div className="home-top-border"></div>
                </Grid>
                <div className="grid-1">
                  <FontAwesomeIcon
                    className="icon"
                    style={{ color: `${value.bg}` }}
                    icon={value.icon}
                  ></FontAwesomeIcon>
                  <h2 className="home-heading">{value.heading}</h2>
                  <p className="home-description">{value.description}</p>
                </div>
                <Grid className="home-button-main">
                  <Stack spacing={2} direction="row" className="home-button">
                    <Button style={{textTransform:"capitalize"}} className="home-button-1" value = {value.button} variant="outlined" onClick={navigation}>
                      {value.button}
                    </Button>
                  </Stack>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
        
      </Grid>
    </Grid>
    </Box>
    <Box>
      <Footer/>
    </Box>
   
    
    </div>
  );
}