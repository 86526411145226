import { AppBar, Avatar, Box, Button, IconButton, Input, Menu, MenuItem, Stack, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from "axios";
import React from "react";
import { useState } from "react";
import { Toast } from "react-bootstrap";

import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from "../../../api/Api";
import Ec2Url from "../../../api/Service";
import { addCvs } from "../../../Endpoints/Endpoints";
import Footer from "../../footer/Footer";
import './managepresentation.css';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

const AddCv = ()=>
{
    const {state}=useLocation();
    const [auth, setAuth] = useState(true);
    const [anchorElUser, setAnchorElUser] = useState();
    const navigate = useNavigate();
    const navToback = ()=>
    {
       if(state.userName)
       {
        let userName=state.userName;
        navigate("/To-Cvs",{state:{userName}});
       }
    }
    const [file,setFile]=useState();
    const [name,setName]=useState();
    const saveFile = (e)=>
    {
      let fileName = e.target.files[0].name;
    var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    if(ext === "docx" || ext==="doc"){
       setFile(e.target.files[0]); 
      console.log(e.target.files[0],"file"); 
    }
    else
    {
      alert1();
    }
    }
    const alert1 = ()=>toast.warn("Enter Valid (.docx/doc)");
    const alert2= ()=>toast.warn("Fill all details !", {
    })
    const notify = () => toast.success("Cv added successfully!",{icon: "🚀"});
    const [errorMessage,setErrorMessage]=useState("Something went wrong..!");
    const notifyError = (e)=>toast.error(errorMessage);
    const saveName = (e)=>
    {
        setName(e.target.value);
        // console.log(e.target.value,"name");
    }
    const addCv = ()=>
    {
     if(file && name){
      let formData = new FormData();
      formData.append("file", file);
      formData.append("filename",name);
      
      let filename1 = {
        "filename":name,
      }
      const token=JSON.parse(localStorage.getItem('Token'));
      const AuthStr = 'Bearer '+token['token'];
       axios.post(`${Ec2Url}${addCvs}`,formData,{headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': AuthStr
      }}).then(
        (Response)=>{
             console.log(Response.data);
             notify();
        },
        (error)=>{
               console.log(error.response.data.message,"errorM")
               setErrorMessage(error.response.data.message);
               notifyError();
        }
      )
     }
     else
     {
      alert2();
     }
      }
    
  return <>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header'>
    <span className='header-2'></span>
    </div>
    <ToastContainer />
    </AppBar>
    </ThemeProvider>
    </Box>
    <form></form>
    <div className='content-2'>
        <div className='form-div-1' style={{width:"109%",display:"flex",flexDirection:'column',justifyContent:"center"}}>
            <div className='logo-wrapper-1'>
                <span style={{fontSize:"25px"}}>Acceptable format (.docx)</span>
            </div>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      
      <div>
        <TextField

          className='adminusername'
          id="outlined-required"
          label="CV Name"
          autoComplete="off"
          onChange={saveName}
        />
      </div>
      {/* <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="new-password"
        /> */}
      <div style={{height:"50px",display:"flex",justifyContent:"center", padding:"10px"}}> 
      <Input name="upload-file" type="file" inputProps={{ accept: 'docx' }}  onChange={saveFile}/>
      {/* <input
      name="upload-photo"
      type="file"
      accept='pdf/*'
      onChange={saveFile}
      />   */}
      </div>  
    </Box>
    <div>
        <Stack spacing={2} direction="row">
            <Button style={{ textTransform:"capitalize"}}
            variant="contained"
            className="button-1"
            onClick={addCv}
            >
            Create
            </Button>
            <Button variant="contained" className="button-2" onClick={navToback} style={{ textTransform:"capitalize"}}>
            Cancel
            </Button>
        </Stack>
    </div>
        </div>
    </div>
    <Footer/>
  </>
}

export default AddCv;