import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect } from "react";
import './managepresentation.css'
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
  });

function TabPanel(props) {


  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export  function SlidePresentation() {
    const {state} = useLocation();
    console.log(state, "Use Nav data");
  const [auth, setAuth] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  async function handleCloseUserMenu() {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
const userLogout =()=>
{
  setAnchorElUser(null);
  sessionStorage.clear();
  localStorage.clear();
  navigate("/");
}


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleToSlideLangingPage = (event) =>
  {
    navigate("/admin-landing/slides-list");
  }

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <Toolbar className='pre-sales'>
    <Typography variant="h6" component="div" 
            sx={{ flexGrow: 1 }}>
            <div style={{fontSize:"20px"}}>
                <div className="Pre-Sales-1" style={{height:"24px"}}>  
                PRE-SALES ADMIN
                </div>
            </div>
            </Typography>
            {auth && (
                <div>
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{color:"black", backgroundColor:"transparent"}} alt="Remy Sharp" src="" />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                    <MenuItem style={{display: "flex", justifyContent:"center"}}>
               <Typography textAlign="center">{state.userName}</Typography>
               </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} style={{display: "flex", justifyContent:"center"}}>
                  <Typography textAlign="center">
                  <Button style={{backgroundColor:'black',color:"white",borderRadius:"4px"}}onClick={()=>{userLogout()}}>LOGOUT&nbsp;<LogoutIcon style={{fontSize:'medium'}}/></Button>
                    </Typography>
                </MenuItem>
                </Menu>
            </Box>
                </div>
            )}
            </Toolbar>
        </AppBar>
        </ThemeProvider>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color="primary" enableColorOnDark>
    <div className='header'>
    <Button className='Button-butter' onClick={handleToSlideLangingPage}>back</Button>
    {/* <span className='header-1'>Master Presentations</span> */}
    </div>
    </AppBar>
    </ThemeProvider>
    </Box>
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar position="static" style={{backgroundColor:"white"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs example"
          
        >
          {state?.data.map((items,index)=>(
          <Tab  
          icon={<img src={items.imageUrl}  className="tab-image-1"
          // style={{height: "105px",border: "2px solid #0F2C67"}}
        />} key={index} {...a11yProps(index)} />
         ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       {state?.data.map((item, index) => (
          <TabPanel value={value} index={index}>
            <img
              src={item.imageUrl}
              className="tab-image"
              style={{
                height: "54vh",
                marginLeft: "25%",
                marginTop: "10px",
                width: "50vw",
                borderRadius: "10px"
              }}
              alt=""
            />
            
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box>
    </>
  );
}