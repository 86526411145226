import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import Button from '@mui/material/Button';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
// import Stack from '@mui/material/Stack';
// import axios from "axios";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Await, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import TablePagination from "@mui/material/TablePagination";
import "./ViewFiles.css";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import { Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Ec2Url from "../../../api/Service";
import { getUserFiles } from "../../../Endpoints/Endpoints";
import Logout from "../../Logout/logout";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import backgroundimage from "../../../Assets/images/background-10.png"
import backgroundTopImg from "../../../Assets/images/Rectangle-1.png"

const columns = [
  { id: "ID", label: "S/N", minWidth: 100, align: "center" },
  { id: "FileName", label: "File Name", minWidth: 100, align: "center" },
  { id: "CreateDate", label: "Created Date", minWidth: 100, align: "center" },
  { id: "Action", label: "Action", minWidth: 100, align: "center" },
];

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
  },
});

const ViewFiles = (props) => {
  const [fetchedFiles, setFetchedFiles] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();

  // console.log(state,"in view");
  useEffect(() => {
    fetchFiles();
  }, [props]);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const fetchFiles = () => {
    const token = JSON.parse(localStorage.getItem("Token"));
    const AuthStr = "Bearer " + token["token"];
    const finalId = 0;

    const id = { id: state.udata.uid };
    // console.log(id,"idinfiles2");
    axios
      .post(`${Ec2Url}${getUserFiles}`, id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
      })
      .then(
        (Response) => {
          // console.log(Response.data.result,"FetchedFiles");
          setFetchedFiles(Response.data.result);
        },
        (error) => {
          console.log(error);
          if (error.response.data.message === "Token Expired") {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        }
      );
  };

  const [value3, setValue3] = React.useState(0);
  const theme = useTheme();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const [page, setPage] = React.useState(2);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataHandler, setDataHandler] = useState();

  const handleChange = (event, newValue) => {
    setValue3(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue3(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const checkStatus = (title, section) => {
    if (title.length > 0 && section.length > 0) {
      return true;
    }
    return false;
  };
  const onDownload = (fileLink) => {
    const link = document.createElement("a");
    // link.download = finalFile;
    link.href = fileLink;
    link.click();
  };

  async function handleCloseUserMenu() {
    setAnchorElUser(null);
    //localStorage.clear()
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleToHomePage = (event) => {
    navigate("/User");
  };

  const userLogout = () => {
    setAnchorElUser(null);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // -----------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertDT = (date) => {
    let stringDate = date.toString();
    const dataArray = stringDate.split("T");
    const dateA = dataArray[0];
    const timeA = dataArray[1];
    const dateArray = dateA.split("-");
    const dateDay = dateArray[2];
    const dateMonth = dateArray[1];
    const dateYear = dateArray[0];
    const timeArray = timeA.split(":");
    const timeHour = timeArray[0];
    const timeMinute = timeArray[1];

    const simpleDateTime =
      dateDay +
      "/" +
      dateMonth +
      "/" +
      dateYear +
      " " +
      timeHour +
      ":" +
      timeMinute;

    return simpleDateTime;
  };
  return (
    <div style={{ height: "100vh" }}>
      <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <div className="logo" style={{ display: "flex",padding:"5px" }}>
                  <div className="logo-div">
                    <img
                      className="absyzimg image"
                      style={{ height: "50px" }}
                      src={require("../../../Assets/images/absyz_blue.png")}
                      alt="Absyz"
                    />

                    <div className="logo-text">
                      <p
                        className="pre-salestext"
                        style={{
                          marginTop: "5px", marginBottom: "-10px", color: "rgba(11, 38, 99, 1)",fontSize:"20px",fontWeight:"600"
                        }}
                      >
                        Pre-Sales
                      </p>
                      <span
                        className="assistented-proposalText"
                        style={{ color: "#3e3e3c", fontSize:"14px",lineHeight:"21px",fontWeight:"400"}}
                      >
                        Assisted Proposal Creation
                      </span>
                    </div>
                  </div>
                  {/* <Box sx={{ flexGrow: 0, marginTop: "10px" }}>
                    <Tooltip title="Open settings">
                      <IconButton  onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          style={{
                            color: "cornflowerblue",
                            backgroundColor: "white",
                          }}
                          alt="Remy Sharp"
                          src=""
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem>
                        <Typography textAlign="center">
                          {state.udata.uname}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseUserMenu}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography textAlign="center">
                          <Button
                            style={{ backgroundColor: "black" }}
                            onClick={() => {
                              userLogout();
                            }}
                          >
                            Logout&nbsp;
                            <LogoutIcon style={{ fontSize: "medium" }} />
                          </Button>
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box> */}
                   <Logout/>
                </div>
              </Typography>

              <Tabs
                selectionFollowsFocus
                value={value3}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                className="header-tabs"
              ></Tabs>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value3}
          onChangeIndex={handleChangeIndex}
        ></SwipeableViews>
      </Box>

      <Box className="main-div" style={{ height: "100%" ,backgroundImage: `url(${backgroundimage}`,
          backgroundSize:"cover"}}>
      <div
        style={{
          height: "7%",
          width: "100%",
          // backgroundColor: "rgba(66, 185, 197, 1)",
          display: "flex",
          alignItems: "center",
          backgroundImage:`url(${backgroundTopImg})`
        }}
      >
        <div className="element_style">
          <Button
            className="button"
            style={{ cursor: "pointer", textTransform:"capitalize",fontFamily:"unset"}}
            onClick={handleToHomePage}
          >
            <ArrowBackIosIcon style={{height:'16px',marginTop:"2px"}}/>
            Back
          </Button>
          <div
            className="text"
            // style={{ width:"100%",display:"flex",justifyContent:"center" }}
          >
            My Files
          </div>
        </div>
      </div>
        <Box
          style={{
            height: "85%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* ------------------------------------------------------------- */}
          <div className="login-box">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table" className = "tablechange">
                  <TableHead>
                    <TableRow >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            display: column.display,
                            justifyContent: column.justifyContent,
                            color:"white",
                            backgroundColor:"rgb(23 45 84)"
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchedFiles
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => (
                        <TableRow 
                          key={row.id}
                          className="row-Styles"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "8px",
                          }}
                        >
                          <TableCell align="center" component="th" scope="row" style={{color:"white"}}>
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ marginRight: "40px" ,color:"white"}}
                          >
                            {row.visibleName}
                          </TableCell>
                          <TableCell align="center" style={{color:"white"}}>
                            {convertDT(row.createdDate)}
                          </TableCell>
                          <TableCell align="center" style={{cursor:"pointer"}}>
                            <SaveAltIcon style={{color:"white"}}
                              onClick={() => {
                                onDownload(row.ppturl);
                              }}
                              variant="contained"
                            ></SaveAltIcon>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination style={{backgroundColor:"rgb(23 45 84)", color:"white"}}
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={fetchedFiles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ViewFiles;
