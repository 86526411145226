import React from "react";

import { useLocation, useParams } from "react-router-dom";
import FileViewer from 'react-file-viewer';
import DocViewer,{ DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AllDocViewer from 'all-doc-viewer-fixed';
import Iframe from "react-iframe";
import { width } from "@mui/system";
import './managepresentation.css'

const ViewCvfromAdmin = ()=>
{
    const {state} = useLocation();
    const {testvalue}=useParams();
    console.log(localStorage.getItem("cvurl"),"testValueComing");
    const cvurl=localStorage.getItem("cvurl");
    
    const url=`//docs.google.com/viewer?embedded=true&url=${cvurl}`;
    return <div className="viewer doc" style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20px"}}>

    <Iframe className="frame" src={url} width="900" height="780" scrolling="no" frameBorder={"none"} styles={{justifyContent:"center"}}/>
    {/* <iframe src="//docs.google.com/viewer?embedded=true&url=https://absyz-presales.s3.ap-south-1.amazonaws.com/cv/Haseeb_TA_ABSYZ_Others.docx" width="600" height="780" style={}> */}
  </div>

 
    

}

export default ViewCvfromAdmin;