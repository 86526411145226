import { faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuAppBar from "../Navigation/Navigation";
import "./style.css";
import fileIcon from "../../Assets/images/Group.png";
import proposalIcon from "../../Assets/images/g3264.png";
import cvIcon from "../../Assets/images/g4935.png";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../SecureLocal/Utill";
import SECRET_KEY from "../SecureLocal/Secretkey";
import CryptoJS from "crypto-js";

const Title_Info = ["Pre-Sales", "Assisted Proposal Creation"];

const Home2 = () => {
  const Navigate = useNavigate();
  //to do decrption of role
  const retrievedRole = localStorage.getItem("Mainrole");
  const bytesRole = CryptoJS.AES.decrypt(retrievedRole, SECRET_KEY);
  const role = JSON.parse(bytesRole.toString(CryptoJS.enc.Utf8));
  // const role= JSON.parse(localStorage.getItem("Mainrole"));

  //console.log(typeof role, "roleofauser");
  const [information, setInformation] = useState(Title_Info);
  useEffect(() => {
    if (localStorage.getItem("Token").length < 0) {
      Navigate("/");
    }

    if (!sessionStorage.getItem("data")) {
      Navigate("/");
    }
    userData();
  }, []);

  const [udata, setUdata] = useState();
  const userData = () => {
    let mkLocalData = sessionStorage.getItem("data");
    if (!mkLocalData) {
    }
    const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
    let originalData = decryptData(mkLocalData, salt);
    setUdata(originalData);
  };
  const navigation = (e) => {
    if (e.target.value === "create") {
      let uid = udata.uid;
      Navigate("/presentation", { state: { udata } });
    } else if (e.target.value === "View Files") {
      let uname = udata.uname;
      let uid = udata.uid;
      Navigate("/ViewFiles", { state: { udata } });
    } else if (e.target.value === "View CV's") {
      let uname = udata.uname;
      Navigate("/View-cvs", { state: { uname } });
    }
  };
  return (
    <>
      <div style={{ height: "100vh" }}>
        <MenuAppBar Info={Title_Info} />
        <Grid
          sx={{ flexGrow: 1 }}
          style={{
            backgroundColor: "#F5F5F5",
            height: "100%",
            paddingTop: "0px",
            marginTop: "0px",
            // overflow:"scroll",
            width: "102%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          container
          spacing={2}
          className="home2-bg"
        >
          <Grid style={{ height: "75%", marginRight: "80px" }}>
            _
            <Grid
              className="home2_card1"
              style={{ display: "flex", margin: "25px", borderRadius: "5px" }}
            >
              <div
                style={{
                  width: "65px",
                  height: "65px",
                  padding: "17px",
                  backgroundColor: "#62af7b",
                  borderRadius: "55px",
                  marginTop: "10px",
                  marginLeft: "14px",
                }}
              >
                <img src={fileIcon} alt="" />
              </div>
              <div>
                <h1
                  style={{
                    paddingTop: "10px",
                    marginTop: "5px",
                    fontWeight: "600",
                    color: "white",
                    fontSize: "18px",
                    marginLeft: "10px",
                    width: "177px",
                    marginBottom: "0px",
                  }}
                >
                  Create Proposals
                </h1>
                <h1
                  style={{
                    fontWeight: "400",
                    color: "white",
                    fontSize: "12px",
                    marginLeft: "12px",
                    marginTop: "0px",
                    width: "150px",
                  }}
                >
                  Create and customize proposals
                </h1>
              </div>

              <Button
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#336393",
                  color: "white",
                  height: "37%",
                  justifyContent: "space-evenly",
                  marginTop: "26px",
                  marginRight: "2px",
                  width: "90px",
                  fontSize: "12px",
                }}
                className=""
                variant="outlined"
                value="create"
                onClick={navigation}
              >
                Create
              </Button>
            </Grid>
            {role === 3 ? (
              <Grid
                className="home2_card2"
                style={{
                  display: "flex",
                  margin: "25px",
                  borderRadius: "5px",
                  opacity: "0.3",
                  cursor: "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "65px",
                    height: "65px",
                    padding: "17px",
                    backgroundColor: "#62af7b",
                    borderRadius: "55px",
                    marginTop: "10px",
                    marginLeft: "14px",
                  }}
                >
                  <img src={proposalIcon} alt="" />
                </div>
                <div>
                  <h1
                    style={{
                      paddingTop: "10px",
                      marginTop: "5px",
                      fontWeight: "600",
                      color: "white",
                      fontSize: "18px",
                      marginLeft: "10px",
                      width: "177px",
                      marginBottom: "0px",
                    }}
                  >
                    My Proposals
                  </h1>
                  <h1
                    style={{
                      fontWeight: "400",
                      color: "white",
                      fontSize: "12px",
                      marginLeft: "11px",
                      marginTop: "0px",
                      width: "150px",
                    }}
                  >
                    Download Proposals From Your Repository{" "}
                  </h1>
                </div>

                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#336393",
                    color: "white",
                    height: "37%",
                    justifyContent: "space-evenly",
                    marginTop: "26px",
                    marginRight: "2px",
                    width: "90px",
                    fontSize: "12px",
                    cursor: "not-allowed",
                  }}
                  disabled={true}
                  value="View Files"
                  className=""
                  variant="outlined"
                  onClick={navigation}
                >
                  View Files
                </Button>
              </Grid>
            ) : (
              <Grid
                className="home2_card2"
                style={{ display: "flex", margin: "25px", borderRadius: "5px" }}
              >
                <div
                  style={{
                    width: "65px",
                    height: "65px",
                    padding: "17px",
                    backgroundColor: "#62af7b",
                    borderRadius: "55px",
                    marginTop: "10px",
                    marginLeft: "14px",
                  }}
                >
                  <img src={proposalIcon} alt="" />
                </div>
                <div>
                  <h1
                    style={{
                      paddingTop: "10px",
                      marginTop: "5px",
                      fontWeight: "600",
                      color: "white",
                      fontSize: "18px",
                      marginLeft: "10px",
                      width: "177px",
                      marginBottom: "0px",
                    }}
                  >
                    My Proposals
                  </h1>
                  <h1
                    style={{
                      fontWeight: "400",
                      color: "white",
                      fontSize: "12px",
                      marginLeft: "11px",
                      marginTop: "0px",
                      width: "150px",
                    }}
                  >
                    Download Proposals From Your Repository{" "}
                  </h1>
                </div>

                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#336393",
                    color: "white",
                    height: "37%",
                    justifyContent: "space-evenly",
                    marginTop: "26px",
                    marginRight: "2px",
                    width: "90px",
                    fontSize: "12px",
                  }}
                  value="View Files"
                  className=""
                  variant="outlined"
                  onClick={navigation}
                >
                  View Files
                </Button>
              </Grid>
            )}
            {role === 3 ? (
              <Grid
                className="home2_card3"
                style={{
                  display: "flex",
                  margin: "25px",
                  borderRadius: "5px",
                  opacity: "0.3",
                  cursor: "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "65px",
                    height: "65px",
                    padding: "17px",
                    backgroundColor: "#62af7b",
                    borderRadius: "55px",
                    marginTop: "10px",
                    marginLeft: "14px",
                  }}
                >
                  <img src={cvIcon} alt="" />
                </div>
                <div>
                  <h1
                    style={{
                      paddingTop: "10px",
                      marginTop: "5px",
                      fontWeight: "600",
                      color: "white",
                      fontSize: "18px",
                      marginLeft: "10px",
                      width: "177px",
                      marginBottom: "0px",
                    }}
                  >
                    CVs
                  </h1>
                  <h1
                    style={{
                      fontWeight: "400",
                      color: "white",
                      fontSize: "12px",
                      marginLeft: "12px",
                      marginTop: "0px",
                      width: "150px",
                    }}
                  >
                    View And Download CVs Of Various Profiles
                  </h1>
                </div>

                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#336393",
                    color: "white",
                    height: "37%",
                    justifyContent: "space-evenly",
                    marginTop: "26px",
                    marginRight: "2px",
                    width: "90px",
                    fontSize: "12px",
                    cursor: "not-allowed",
                  }}
                  disabled={true}
                  value="View CV's"
                  className=""
                  variant="outlined"
                  onClick={navigation}
                >
                  View CVs
                </Button>
              </Grid>
            ) : (
              <Grid
                className="home2_card3"
                style={{ display: "flex", margin: "25px", borderRadius: "5px" }}
              >
                <div
                  style={{
                    width: "65px",
                    height: "65px",
                    padding: "17px",
                    backgroundColor: "#62af7b",
                    borderRadius: "55px",
                    marginTop: "10px",
                    marginLeft: "14px",
                  }}
                >
                  <img src={cvIcon} alt="" />
                </div>
                <div>
                  <h1
                    style={{
                      paddingTop: "10px",
                      marginTop: "5px",
                      fontWeight: "600",
                      color: "white",
                      fontSize: "18px",
                      marginLeft: "10px",
                      width: "177px",
                      marginBottom: "0px",
                    }}
                  >
                    CVs
                  </h1>
                  <h1
                    style={{
                      fontWeight: "400",
                      color: "white",
                      fontSize: "12px",
                      marginLeft: "12px",
                      marginTop: "0px",
                      width: "150px",
                    }}
                  >
                    View And Download CVs Of Various Profiles
                  </h1>
                </div>

                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#336393",
                    color: "white",
                    height: "37%",
                    justifyContent: "space-evenly",
                    marginTop: "26px",
                    marginRight: "2px",
                    width: "90px",
                    fontSize: "12px",
                  }}
                  value="View CV's"
                  className=""
                  variant="outlined"
                  onClick={navigation}
                >
                  View CVs
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Home2;
